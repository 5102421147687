import { EastRounded } from "@mui/icons-material";

interface props {
  children: string | React.ReactNode;
  color: string;
  active?: boolean;
  setActive?: Function;
  type?: "button" | "submit" | "reset" ;
}

export function ButtonV1({ children, color, active, setActive, type }: props) {  
  return (
    <button
      type={type || 'button'}
      disabled={active || false}
      onClick={() => setActive && setActive()}
      className={`w-full my-1 capitalize text-white p-2 px-4 border-none rounded-xl whitespace-nowrap ${
        color === "red" ? "bg-red-500" : "bg-blue-500"
      }`}
    >
      {children}
    </button>
  );
}

export function ButtonV2({ children, color, active, setActive }: props) {
  return (
    <button
      onClick={() => setActive && setActive()}
      className={`w-full my-1 capitaliz
      e p-2 border rounded-xl ${
        color === "red"
          ? "border-red-500 text-red-500"
          : "border-blue-500 text-blue-500"
      }`}
    >
      {children}
    </button>
  );
}

export function ButtonV3({ children, color, active, setActive }: props) {
  return (
    <button
      onClick={setActive && setActive()}
      className={`w-full my-1 capitalize p-2 border-none rounded-xl ${
        color === "red"
          ? "hover:bg-red-500 text-red-500"
          : "hover:bg-blue-500 text-blue-500"
      }  hover:text-white`}
    >
      {children}
    </button>
  );
}

export function ButtonV4({ children, color, active, setActive }: props) {
  return (
    <button
      onClick={() => setActive && setActive()}
      className={`w-max capitalize p-2 border-none rounded-xl ${
        color === "red"
          ? "hover:bg-red-500 text-red-500"
          : "hover:bg-blue-500 text-blue-500"
      }  hover:text-white`}
    >
      {children}
    </button>
  );
}

interface ButtonProps {
  children: string;
  link?: string;
  disable?: Boolean;
}

export function SubmitButton({ children, disable }: ButtonProps) {

  return (
    <button type={`${!disable ? 'submit' : 'button'}`} className={`w-[100%] shadow-md rounded-md p-2 px-6 bg-[#28166F] flex text-[0.9rem] leading-[200%] font-medium text-white justify-between items-center ${disable && 'opacity-90 cursor-not-allowed'}`}>
        {children}
        <EastRounded className="text-white text-[1.2rem]" />
    </button>
  );
}