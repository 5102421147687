import { storeActions } from "./storeSlice";

interface storeDetails {
  brand?: {
    _id: string,
    title: string,
  }[],
  category?: {
    _id: string,
    title: string,
  }[],
  manufacturer?: {
    _id: string,
    title: string,
  }[],
}

const storeHandler = (storeDetails: storeDetails) => {
  return (dispatch: (arg0: { payload: any; type: "data/store"; }) => void) => {

    const {
      brand,
      category,
      manufacturer

    } = storeDetails;
    dispatch(
      storeActions.store({
        brand,
        category,
        manufacturer,
      }));
  };
};

const storeCleanHandler = (storeDetails: storeDetails) => {
  return (dispatch: (arg0: { payload: any; type: "data/storeClean"; }) => void) => {

    const {
      brand,
      category,
      manufacturer,
    } = storeDetails;

    dispatch(storeActions.storeClean({
      brand,
      category,
      manufacturer
    }));
  };
};


const logoutFilterHandler = () => {
  return (dispatch: (arg0: { payload: undefined; type: "data/logout"; }) => void) => {
    console.log("cleaned store");
    dispatch(storeActions.logout());
  };
};

export { storeHandler, storeCleanHandler, logoutFilterHandler }
