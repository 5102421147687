import { FormikValues } from "formik";
import {
  ChangeEventHandler,
  WheelEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FaArrowCircleLeft } from "react-icons/fa";
import { FaArrowCircleRight } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useDeleteImgMutation, useUploadImgMutation } from "../../api/fileUpload";
interface fileProps {
  name: string;
  lable?: string;
  type: string;
  accept?: string;
  formik?: FormikValues;
  value?: string;
  width?: string;
  handleChange?: Function;
  image?: string[];
  setImage?: Function;
}

interface singeFileProp {
  name: string;
  lable?: string;
  type: string;
  accept?: string;
  formik?: FormikValues;
  value?: string;
  width?: string;
  handleChange?: Function;
  image?: string;
  setImage?: Function;
}

interface props {
  name: string;
  type: string;
  width?: string;
  active?: boolean;
  lable?: string;
  errors?: string;
  touched?: string;
  placeholder?: string;
  value?: number | string;
  handleBlur?: ChangeEventHandler<HTMLInputElement>;
  handleChange?: ChangeEventHandler<HTMLInputElement>;
}

interface props2 {
  name: string;
  lable?: string;
  type: string;
  placeholder?: string;
  formik?: FormikValues;
  value?: number | string;
  width?: string;
  handleChange?: Function;
}

export function TextEditor({
  lable,
  type,
  placeholder,
  value,
  handleChange,
  name,
  formik,
  width,
}: props2) {
  const handleChangeData = (event: any, editor: any) => {
    const data = editor.getData();
    formik && formik.setFieldValue(name, data);
    console.log("🚀 ~ handleChangeData ~ data:", formik && formik.values[name]);
  };

  return (
    <div className={`flex flex-col ${width ? "w-[47%]" : "w-full"} gap-1 mb-4`}>
      {lable && (
        <label className="font-semibold text-sm capitalize">{lable}</label>
      )}
      <CKEditor
        editor={ClassicEditor}
        data={`${value}`}
        onChange={handleChangeData}
      />
      {formik && !!formik.touched[`${name}`] && formik.errors[`${name}`] && (
        <p className="text-[#F45B69]">{formik.errors[`${name}`]}</p>
      )}
    </div>
  );
}

export function InputFieldV1({
  lable,
  type,
  placeholder,
  value,
  touched,
  errors,
  handleChange,
  handleBlur,
  name,
  width,
  active
}: props) {
  const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const input = inputRef.current;
        if (input) {
            // Adding event listener with the correct typing
            input.addEventListener('wheel', ((e) => e.preventDefault()), { passive: false });
            return () => {
                input.removeEventListener('wheel', ((e) => e.preventDefault()));
            };
        }
    }, []);
    
  return (
    <div className={`flex flex-col ${width ? "w-[47%]" : "w-full"} gap-1 mb-4`}>
      {lable && (
        <label className="font-semibold text-sm capitalize">{lable}</label>
      )}
      <input
        disabled={active}
        ref={inputRef}
        name={name}
        type={type}
        placeholder={placeholder}
        className="p-2 border rounded-xl touch-none"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        // onWheel={handleWheel}
      />
      {(touched || errors) && <p className="text-[#F45B69]">{errors}</p>}
    </div>
  );
}

export function InputFieldV2({
  lable,
  type,
  placeholder,
  handleChange,
  name,
  value,
}: props2) {
  return (
    <div className="flex flex-col w-full gap-1 mb-4">
      {lable && (
        <label className="font-semibold text-sm capitalize">{lable}</label>
      )}
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        className="p-2 border rounded-xl"
        defaultValue={value && value}
        onChange={(e) => handleChange && handleChange(e.target.value)}
      />
    </div>
  );
}

export function SingleFileInput({
  lable,type,accept,formik,name,width,image}:singeFileProp) {
    const [selectedFile,setSelectedFile] = useState<string>(image || "");
    const [uploadImg] = useUploadImgMutation();

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const file = event?.target.files?.[0] || null; 
        if (!file) {
          console.log("No file selected");
          return;
        }
    
        console.log('file ==> ', file);
        const response: any = await uploadImg({ file });
    
        if (response.data) {
          const fields = response.data.data.fields;
          const url = response.data.data.url;
          const formData = new FormData();
    
          // Append required fields to the formData
          for (const [key, value] of Object.entries(fields)) {
            formData.append(key, value as string);
          }
    
          formData.append("file", file);
    
          const uploadResponse = await fetch(url, {
            method: "POST",
            body: formData,
          });
    
          if (uploadResponse.status === 204) {
            const imageUrl = `${url}/${fields.key}`;
            console.log("Image successfully uploaded, URL:", imageUrl);
    
            setSelectedFile(imageUrl); 
          } else {
            console.error("Image upload failed");
          }
        } else {
          console.error("Error in response:", response.error);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    
    useEffect(() => {
      formik && formik.setFieldValue(name,selectedFile);
      formik && formik.setFieldValue("imgUrl",selectedFile);
    },[selectedFile])


    return (
      <div className="flex flex-col my-1 gap-2">
        {lable && (
          <label className="font-semibold text-sm capitalize">{lable}</label>
        )}
        <input type={type} name={name} accept={accept} onChange={handleFileChange} />
        {selectedFile && (
          <img src={selectedFile} alt="Selected file" style={{ width: width || '100px' }} />
        )}
      </div>
    )
  }

export function FileInputField({
  lable,
  type,
  accept,
  formik,
  name,
  width,
  image,
  setImage,
}: fileProps) {
  const [selectedFile, setSelectedFile] = useState<string[]>(image || [""]);

  const [uploadImg] = useUploadImgMutation();

  const handleFileChange = async (file: File | undefined) => {
    try {
      const response: any = await uploadImg({ file });
      if (response.data) {
        if (file instanceof File) {
          const fields = response.data.data.fields;
          const url = response.data.data.url;
          console.log("🚀 ~ handleFileChange ~ url:", url);
          const formData = new FormData();

          for (const [key, value] of Object.entries(fields)) {
            formData.append(key, value as string);
          }

          formData.append("file", file);
          const uploadResponse = await fetch(url, {
            method: "POST",
            body: formData,
            mode: "cors",
          });

          console.log(
            "🚀 ~ handleFileChange ~ uploadResponse:",
            uploadResponse
          );
          if (uploadResponse.status === 204) {
            console.log(
              "🚀 ~ handleFileChange ~ uploadResponse:",
              uploadResponse
            );
            const imageUrl = `${url}/${fields.key}`;
            setSelectedFile((prevSelectedFiles: string[]) =>[...prevSelectedFiles,imageUrl]);
            console.log("🚀 ~ handleFileChange ~ imageUrl:", imageUrl);
            setImage && setImage(imageUrl);
          } else {
            console.error("Image upload failed");
          }
        }
      } else {
        console.error("Error in response:", response.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    // const file = event.target.files?.[0];
    // console.log(file);

    // if (file) {
    //   const reader = new FileReader();
    //   reader.onload = () => {
    //     setSelectedFile(reader.result as string);
    //   };
    //   reader.readAsDataURL(file);
    // }
  };

  return (
    <div className={`flex flex-col ${width ? "w-[47%]" : "w-full"} gap-1 mb-4`}>
      {lable && (
        <label className="font-semibold text-sm capitalize">{lable}</label>
      )}
      <div className="flex flex-col">
        <input
          name={name}
          type={type}
          accept={accept}
          className="p-2 border rounded-xl"
          onChange={(e) => handleFileChange(e?.target?.files?.[0])}
        />
        <div className="m-auto">
          {selectedFile.length &&
            (accept === "image/*"
              ? selectedFile.map(
                  (item, index) =>
                    item !== "" && (
                      <img
                        key={index}
                        src={item}
                        alt="Selected"
                        style={{
                          maxWidth: "300px",
                          maxHeight: "300px",
                          marginTop: "10px",
                        }}
                      />
                    )
                )
              : selectedFile.map(
                  (item, index) =>
                    item !== "" && (
                      <video
                        key={index}
                        controls
                        style={{
                          maxWidth: "300px",
                          maxHeight: "300px",
                          marginTop: "10px",
                        }}
                        src={item}
                      />
                    )
                ))}
        </div>
      </div>{" "}
      {formik && !!formik.touched[`${name}`] && formik.errors[`${name}`] && (
        <p className="text-[#F45B69]">{formik.errors[`${name}`]}</p>
      )}
    </div>
  );
}

export function MultiFileInputField({
  lable,
  type,
  accept,
  formik,
  name,
  width,
  image,
}: fileProps) {
  const [selectedFile, setSelectedFile] = useState<string[]>(image || [""]);

  const [uploadImg] = useUploadImgMutation();
  const [deleteImg] = useDeleteImgMutation();
  const removeImgHandler = async (imageUrl: string) => {
    try {
      const filePath = imageUrl.split('infinite-green-website/')[1]; 
  
      if (filePath) {
        const response = await deleteImg( filePath ).unwrap();
        if(response.data){
          setSelectedFile((prevSelectedFiles: string[]) =>
            prevSelectedFiles.filter((file) => file !== imageUrl)
          );
        }  
      } else {
        console.error("Failed to extract the file path from the URL");
      }
    } catch (error) {
      console.error("Failed to delete image:", error);
    }
  };
  

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const files = event.target.files;
      if (!files) return;

      const filesArray = Array.from(files);

      for (const file of filesArray) {
        const response: any = await uploadImg({ file });
        if (response.data) {
          if (file instanceof File) {
            const fields = response.data.data.fields;
            const url = response.data.data.url;
            const formData = new FormData();

            for (const [key, value] of Object.entries(fields)) {
              formData.append(key, value as string);
            }

            formData.append("file", file);
            const uploadResponse = await fetch(url, {
              method: "POST",
              body: formData,
            });

            if (uploadResponse.status === 204) {
              const imageUrl = `${url}/${fields.key}`;
              setSelectedFile((prevSelectedFiles: any) => [
                ...prevSelectedFiles,
                imageUrl,
              ]);
              // Assuming setImage is a setter function to update state
              // setImage && setImage(imageUrl);
            } else {
              console.error("Image upload failed");
            }
          }
        } else {
          console.error("Error in response:", response.error);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  const RightMoveHandler = (index:number) => {
    if(index < selectedFile.length - 1) {
      const newSelectedFile = [...selectedFile];
      [newSelectedFile[index+1],newSelectedFile[index]] = [newSelectedFile[index],newSelectedFile[index+1]];
      setSelectedFile(newSelectedFile);
    }
  }

  const leftMoveHandler = (index:number) => {
    if(index > 0) {
      const newSelectedFile = [...selectedFile];
      [newSelectedFile[index-1],newSelectedFile[index]] = [newSelectedFile[index],newSelectedFile[index-1]];
      setSelectedFile(newSelectedFile);
    }
  }

  useEffect(() => {
    formik && formik.setFieldValue(name, selectedFile);
    // formik && formik.setFieldValue("imgUrl", selectedFile[0]);
  }, [selectedFile]);

  return (
    <div className={`flex flex-col ${width ? "w-[47%]" : "w-full"} gap-1 mb-4`}>
      {lable && (
        <label className="font-semibold text-sm capitalize">{lable}</label>
      )}
      <div className="flex flex-col">
        <input
          name={name}
          type={type}
          accept={accept}
          multiple
          className="p-2 border rounded-xl"
          onChange={handleFileChange}
        />
        <div className="m-auto flex w-full overflow-x-scroll gap-2">
          {selectedFile.length !== 0 &&
            (accept === "image/*"
              ? selectedFile.map(
                  (item,index) =>
                    item?.trim() !== "" && (
                      <div className="relative" key={index}>
                        <img
                          className="h-[200px] w-[225px] object-cover"
                          src={item}
                          alt="Selected"
                          style={{
                            maxWidth: "250px",
                            maxHeight: "200px",
                            marginTop: "10px",  
                          }}
                        />
                        <div className="flex items-center justify-evenly px-1 my-1">
                          <button className="" type="button" onClick={() => leftMoveHandler(index)}><FaArrowCircleLeft /></button>
                          <button type="button" onClick={() => RightMoveHandler(index)}><FaArrowCircleRight /></button>
                        </div>
                        <button type="button" className="absolute top-1 right-1 text-xl p-1 bg-white rounded" onClick={() => removeImgHandler(item)}><MdDelete /></button>
                      </div>
                    )
                )
              : selectedFile.map(
                  (item) =>
                    item.trim() !== "" && (
                      <video
                        controls
                        style={{
                          maxWidth: "300px",
                          maxHeight: "200px",
                          marginTop: "10px",
                        }}
                        src={item}
                      />
                    )
                ))}
        </div>
      </div>{" "}
      {formik && !!formik.touched[`${name}`] && formik.errors[`${name}`] && (
        <p className="text-[#F45B69]">{formik.errors[`${name}`]}</p>
      )}
    </div>
  );
}
