import apiSlice from "../features/apiSlice";
import { removeEmptyKeys } from "../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrder: builder.query({
      query: (queries) => {
        return {
          url: '/orders/list',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['Order']
    }),
    createOrder: builder.mutation({
      query: (credentials) => ({
        url: "/orders",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Order']
    }),
    updateOrder: builder.mutation({
      query: (credentials) => ({
        url: `/orders/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['Order']
    }),
    updateStatusOrder: builder.mutation({
      query: (credentials) => ({
        url: `/orders/updateStatus`,
        method: "PUT",
        body: credentials,
      }),
      invalidatesTags:['Order']
    }),
    bulkUpdateOrder: builder.mutation({
      query: (credentials) => ({
        url: `/orders/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Order']
    }),
    deleteOrder: builder.mutation({
      query: (credentials) => ({
        url: `/orders/${credentials.id}`,
        method: "DELETE"
      }),
      invalidatesTags:['Order']
    }),
    hardDeleteOrder: builder.mutation({
      query: (credentials) => ({
        url: `/orders/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Order']
    }),
  }),
});

export const {
  useGetOrderQuery,
  useLazyGetOrderQuery,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useBulkUpdateOrderMutation,
  useUpdateStatusOrderMutation,
  useDeleteOrderMutation,
  useHardDeleteOrderMutation
} = authApiSlice;
