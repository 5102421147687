import { useEffect, useState } from "react";
import {
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
  XAxis,
} from "recharts";

interface props {
  data: {
    Jan: {
      amount: number;
    }[];
    Feb: {
      amount: number;
    }[];
    Mar: {
      amount: number;
    }[];
    Apr: {
      amount: number;
    }[];
    May: {
      amount: number;
    }[];
    Jun: {
      amount: number;
    }[];
    Jul: {
      amount: number;
    }[];
    Aug: {
      amount: number;
    }[];
    Sep: {
      amount: number;
    }[];
    Oct: {
      amount: number;
    }[];
    Nov: {
      amount: number;
    }[];
    Dec: {
      amount: number;
    }[];
  }[];
}

export default function TableChart({ data }: props) {
  console.log(data);
  
  const chartData = [
    {
      name: "Jan",
      Revenue: data[0]?.Jan ? data[0]?.Jan[0]?.amount || 0 : 0,
      Returns: 0,
    },
    {
      name: "Feb",
      Revenue: data[0]?.Feb ? data[0]?.Feb[0]?.amount || 0 : 0,
      Returns: 0,
    },
    {
      name: "Mar",
      Revenue: data[0]?.Mar ? data[0]?.Mar[0]?.amount || 0 : 0,
      Returns: 0,
    },
    {
      name: "Apr",
      Revenue: data[0]?.Apr ? data[0]?.Apr[0]?.amount || 0 : 0,
      Returns: 0,
    },
    {
      name: "May",
      Revenue: data[0]?.May ? data[0]?.May[0]?.amount || 0 : 0,
      Returns: 0,
    },
    {
      name: "Jun",
      Revenue: data[0]?.Jun ? data[0]?.Jun[0]?.amount || 0 : 0,
      Returns: 0,
    },
    {
      name: "Jul",
      Revenue: data[0]?.Jul ? data[0]?.Jul[0]?.amount || 0 : 0,
      Returns: 0,
    },
    {
      name: "Aug",
      Revenue: data[0]?.Aug ? data[0]?.Aug[0]?.amount || 0 : 0,
      Returns: 0,
    },
    {
      name: "Sep",
      Revenue: data[0]?.Sep ? data[0]?.Sep[0]?.amount || 0 : 0,
      Returns: 0,
    },
    {
      name: "Oct",
      Revenue: data[0]?.Oct ? data[0]?.Oct[0]?.amount || 0 : 0,
      Returns: 0,
    },
    {
      name: "Nov",
      Revenue: data[0]?.Nov ? data[0]?.Nov[0]?.amount || 0 : 0,
      Returns: 0,
    },
    {
      name: "Dec",
      Revenue: data[0]?.Dec ? data[0]?.Dec[0]?.amount || 0 : 0,
      Returns: 0,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={chartData}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#FA3434" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#FA3434" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0D6EFD" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#0D6EFD" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="Revenue"
          stroke="#0D6EFD"
          fillOpacity={1}
          fill="url(#colorPv)"
        />
        <Area
          type="monotone"
          dataKey="Returns"
          stroke="#FA3434"
          fillOpacity={1}
          fill="url(#colorUv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
