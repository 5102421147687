import AdminLayout from "../../components/organisms/adminLayout";
import DataGridBox from "../../components/organisms/dataGrid";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AdminPageFilters from "../../components/organisms/adminPageFilters";
import {
  useBulkUpdateManufacturerMutation,
  useCreateManufacturerMutation,
  useDeleteManufacturerMutation,
  useGetManufacturerQuery,
  useUpdateManufacturerMutation,
} from "../../api/filters/manufacturer";
import { DeletePopup } from "../../components/molecules/DeleteModal";
import { EditModal } from "../../components/molecules/EditModal";
import {
  FileInputField,
  InputFieldV2,
} from "../../components/atoms/inputField";
import { ButtonV1 } from "../../components/atoms/button";
import snackbar from "../../api/snackbar/useSnackbar";

interface Item {
  _id: string;
  name: string;
  imgUrl: string;
}

function ManufacturerPage() {
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [isDelete, setDelete] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [isAddNew, setAddNew] = React.useState(false);
  const [Manufacturer, setManufacturer] = React.useState("");
  const [regex, setRegex] = React.useState<string>("");
  const [sortType, setSortType] = React.useState<string>("createdAt");
  const [updateType, setUpdateType] = React.useState<string>("createdAt");
  const [image, setImage] = React.useState("");
  const [currentId, setCurrentId] = React.useState<GridRowId>("");
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  const {
    data: ManufacturerData,
    isFetching: ManufacturerIsFetching,
    isSuccess: ManufacturerSuccess,
  } = useGetManufacturerQuery({
    name: regex,
    pageSize: paginationModel.pageSize,
    pageNo: paginationModel.page + 1,
    sortType: sortType,
    sortOrder: -1,
  });
  const [update, { isLoading: updateIsLoading, isSuccess: updateSuccess }] =
    useUpdateManufacturerMutation({});

  const [
    bulkUpdateData,
    {
      data: bulkUpdatedData,
      isLoading: isbulkUpdatedLoading,
      isSuccess: isbulkUpdatedSuccess,
    },
  ] = useBulkUpdateManufacturerMutation({});

  const [create, { isLoading: createIsLoading, isSuccess: createSuccess }] =
    useCreateManufacturerMutation({});
  const [
    deleteManufacturer,
    { isLoading: deleteIsLoading, isSuccess: deleteSuccess },
  ] = useDeleteManufacturerMutation({});

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 150, editable: false },
    { field: "name", headerName: "Name", width: 350, editable: false },
    {
      field: "img",
      headerName: "Thumb",
      width: 150,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => (
        <img
          // onClick={handleThumbClick(`${params?.row?.img}`)}
          src={params?.row?.image}
          alt={params?.row?.image}
          style={{ width: "auto", height: "100%", padding: "10px" }} // adjust width and height as needed
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 180,
      cellClassName: "actions",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => [
        <GridActionsCellItem
          icon={<EditIcon className="text-blue-400" />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(params?.row?.id, params?.row?.image, params?.row?.name)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon className="text-red-400" />}
          label="Delete"
          onClick={handleDeleteClick(params?.row?.id)}
          color="inherit"
        />,
      ],
    },
  ];

  useEffect(() => {
    if (ManufacturerSuccess && ManufacturerData) {
      setRows(
        ManufacturerData?.data?.data?.map((item: Item) => ({
          id: item._id,
          name: item.name,
          image: item.imgUrl,
        }))
      );
    }
  }, [ManufacturerData, ManufacturerIsFetching, ManufacturerSuccess]);

  const handleCreateClick = () => {
    setAddNew(true);
  };

  const handleEditClick = (id: string, image: string, name: string) => () => {
    setEdit(true);
    setCurrentId(id);
    setImage(image);
    setManufacturer(name);
    console.log(id);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDelete(true);
    setCurrentId(id);
    console.log(id);
  };

  const [selectedRowIds, setSelectedRowIds] = React.useState<GridRowId[]>([]);

  const handleSelectionChange = (newSelection: GridRowId[]) => {
    console.log(newSelection);
    setSelectedRowIds(newSelection);
  };

  function handleCreate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    create({ name: Manufacturer, imgUrl: image })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setAddNew(false);
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  }

  function handleUpdate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    update({ id: currentId, body: { name: Manufacturer, imgUrl: image } })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setEdit(false);
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  }

  function handleDelete() {
    deleteManufacturer({ id: currentId })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setDelete(false);
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setDelete(false);
      });
  }

  function bulkUpdate() {
    console.log(updateType);

    let data;
    if (updateType === "delete") {
      data = { active: false };
    }
    bulkUpdateData({ id: selectedRowIds, data })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setUpdateType("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setUpdateType("");
      });
  }

  return (
    <AdminLayout>
      <AdminPageFilters
        name="Manufacturer"
        qty={ManufacturerData?.data?.totalCount}
        setAddNew={handleCreateClick}
        setRegex={setRegex}
        bulkUpdate={bulkUpdate}
        setUpdateType={setUpdateType}
        setSortType={setSortType}
        regex={regex}
      />
      <DataGridBox
          checkboxSelection={true}
        columns={columns}
        initialRows={rows}
        rowModesModel={rowModesModel}
        selectedRowIds={selectedRowIds}
        paginationModel={paginationModel}
        setRowModesModel={setRowModesModel}
        qty={ManufacturerData?.data?.totalCount}
        setPaginationModel={setPaginationModel}
        onSelectionModelChange={handleSelectionChange}
      />
      {isDelete && (
        <DeletePopup
          open={isDelete}
          handleClose={() => setDelete(false)}
          handleDelete={handleDelete}
        />
      )}
      {isEdit && (
        <EditModal open={isEdit} handleClose={() => setEdit(false)}>
          <form className="flex flex-col" onSubmit={(e) => handleUpdate(e)}>
            <h1 className="text-lg font-medium mb-4 w-full">Edit Manufacturer</h1>
            <InputFieldV2
              name="title"
              lable="Name of Manufacturer"
              type="text"
              handleChange={setManufacturer}
              value={rows.find((item) => item.id === currentId)?.name}
            />
            <FileInputField
              name="imgUrl"
              lable="Image"
              type="file"
              accept="image/*"
              setImage={setImage}
              image={[image]}
            />
            <ButtonV1 color="blue" type="submit">
              Update
            </ButtonV1>
          </form>
        </EditModal>
      )}
      {isAddNew && (
        <EditModal open={isAddNew} handleClose={() => setAddNew(false)}>
          <form className="flex flex-col" onSubmit={(e) => handleCreate(e)}>
            <h1 className="text-lg font-medium mb-4 w-full">Add Manufacturer</h1>
            <InputFieldV2
              name="title"
              lable="Name of Manufacturer"
              type="text"
              placeholder="Add new Manufacturer"
              handleChange={setManufacturer}
            />
            <FileInputField
              name="imgUrl"
              lable="Image"
              type="file"
              accept="image/*"
              setImage={setImage}
              image={[image]}
            />
            <ButtonV1 color="blue" type="submit">
              Add Manufacturer
            </ButtonV1>
          </form>
        </EditModal>
      )}
    </AdminLayout>
  );
}

export default ManufacturerPage;
