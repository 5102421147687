import AdminLayout from "../../components/organisms/adminLayout";
import DataGridBox from "../../components/organisms/dataGrid";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModesModel,
  GridRowsProp,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";
import snackbar from "../../api/snackbar/useSnackbar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AdminPageFilters from "../../components/organisms/adminPageFilters";
import {
  useBulkUpdateProductMutation,
  useDeleteProductMutation,
  useHardDeleteProductMutation,
  useGetProductQuery,
  useUpdateProductMutation,
} from "../../api/product";
import { ProductType } from "../../types/types";
import { DeletePopup } from "../../components/molecules/DeleteModal";
import {
  AddProductModal,
  EditProductModal,
} from "../../components/molecules/ProductModal";

function ProductPage() {
  const [currentId, setCurrentId] = React.useState<GridRowId>("");
  const [isEdit, setEdit] = React.useState(false);
  const [editItem, setEditItem] = React.useState<ProductType>();
  const [isAddNew, setAddNew] = React.useState(false);
  const [isDelete, setDelete] = React.useState(false);
  const [regex, setRegex] = React.useState<string>("");
  const [sortType, setSortType] = React.useState<string>("createdAt");
  const [updateType, setUpdateType] = React.useState<string>("createdAt");
  const [sortOrder, setSortOrder] = React.useState<Number>(-1);
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [selectedRowIds, setSelectedRowIds] = React.useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  const columns: GridColDef[] = [
    // { field: "srno", headerName: "Sr no.", width: 80, editable: false },
    {
      field: "img",
      headerName: "Thumb",
      width: 250,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => (
        <img
        // onClick={handleThumbClick(`${params?.row?.img}`)}
        src={params?.row?.img}
        alt={params?.row?.img}
        style={{ width: "auto", height: "100%", padding: "10px" }} // adjust width and height as needed
        />
      ),
    },
    { field: "name", headerName: "Name", width: 250, editable: false },
    { field: "qty", headerName: "Inventory", width: 100, editable: false },
    { field: "price", headerName: "Price", width: 100, editable: false },
    {field:"marketPrice",headerName:"Market Price",width: 100, editable: false},
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 180,
      cellClassName: "actions",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon className="text-blue-400" />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.row.id)}
            color="inherit"
            key={`edit-${params.row.id}`}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon className="text-red-400" />}
            label="Delete"
            onClick={handleDeleteClick(params.row.id)}
            color="inherit"
            key={`delete-${params.row.id}`}
          />,
          <GridActionsCellItem
            key={`status-${params.row.id}`}
            icon={
              <p
                className={`flex items-center text-white ${
                  params?.row?.status === "active"
                    ? "bg-green-500"
                    : "bg-red-500"
                } rounded-full p-1 px-2 mr-1 text-xs capitalize`}
              >
                {params?.row?.status}
              </p>
            }
            label="Unpublish"
            onClick={handleSoftDelete(params.row.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const {
    data: ProductData,
    isLoading: isProductLoading,
    isSuccess: isProductSuccess,
  } = useGetProductQuery({
    title: regex,
    pageSize: paginationModel.pageSize,
    pageNo: paginationModel.page + 1,
    sortType: sortType,
    sortOrder: -1,
  });

  const [
    updateData,
    {
      data: updatedData,
      isLoading: isUpdatedLoading,
      isSuccess: isUpdatedSuccess,
    },
  ] = useUpdateProductMutation({});

  const [
    bulkUpdateData,
    {
      data: bulkUpdatedData,
      isLoading: isbulkUpdatedLoading,
      isSuccess: isbulkUpdatedSuccess,
    },
  ] = useBulkUpdateProductMutation({});

  const [
    deleteProduct,
    { isLoading: deleteIsLoading, isSuccess: deleteSuccess },
  ] = useDeleteProductMutation({});

  const [
    hardDeleteProduct,
    { isLoading: hardDeleteIsLoading, isSuccess: hardDeleteSuccess },
  ] = useHardDeleteProductMutation({});

  useEffect(() => {
    if (ProductData) {
      setRows(
        ProductData?.data?.data?.map((item: ProductType, index: number) => ({
          // srno: index + paginationModel.page * paginationModel.pageSize + 1,
          img: item.imgUrl,
          id: item._id,
          name: item.title,
          qty: item.inventory,
          price: item.price.salePrice,
          marketPrice: item.price.marketPrice,
          isHigh: item.inventory > 99 ? true : false,
          status: item.status,
        }))
      );
    }
    // console.log("ProductData", ProductData);
  }, [isProductSuccess, ProductData, paginationModel]);

  const handleEditClick = (id: GridRowId) => () => {
    setEdit(true);
    const item = ProductData?.data?.data.filter(
      (item: ProductType) => item?._id === id
    );
    setEditItem(item[0]);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDelete(true);
    setCurrentId(id);
    console.log(id);
  };

  function handleHardDelete() {
    hardDeleteProduct({ id: currentId })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || 'Success');
        setDelete(false);
        setCurrentId("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setDelete(false);
      });
  }

  const handleSoftDelete = (id: GridRowId) => () => {
    deleteProduct({ id: id })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || 'Success');
        setDelete(false);
        setCurrentId("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setDelete(false);
      });

    console.log(id);
  };

  const handleSelectionChange = (newSelection: GridRowId[]) => {
    console.log(newSelection);
    setSelectedRowIds(newSelection);
  };

  function bulkUpdate() {
    let data;
    if (updateType === "delete") {
      data = { active: false };
    } else if (updateType === "active") {
      data = { status: 'active' };
    } else {
      data = { status: 'inactive' };
    }

    bulkUpdateData({ id: selectedRowIds, data })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || 'Success');
        setUpdateType("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setUpdateType("");
      });
  }

  return (
    <AdminLayout>
      <AdminPageFilters
        name="Products"
        setAddNew={() => setAddNew(true)}
        qty={ProductData?.data?.totalCount}
        setRegex={setRegex}
        bulkUpdate={bulkUpdate}
        setUpdateType={setUpdateType}
        setSortType={setSortType}
        regex={regex}
      />
      <DataGridBox
        checkboxSelection={true}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        columns={columns}
        initialRows={rows}
        qty={ProductData?.data?.totalCount}
        rowModesModel={rowModesModel}
        selectedRowIds={selectedRowIds}
        setRowModesModel={setRowModesModel}
        onSelectionModelChange={handleSelectionChange}
      />
      {isEdit && (
        <EditProductModal
          item={editItem}
          open={isEdit}
          handleClose={() => setEdit(false)}
        />
      )}
      {isAddNew && (
        <AddProductModal open={isAddNew} handleClose={() => setAddNew(false)} />
      )}
      {isDelete && (
        <DeletePopup
          open={isDelete}
          handleClose={() => setDelete(false)}
          handleDelete={() => handleHardDelete()}
        />
      )}
    </AdminLayout>
  );
}

export default ProductPage;
