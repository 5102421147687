import apiSlice from "../features/apiSlice";
import { removeEmptyKeys } from "../utils/helper";

export const revenueApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRevenueList: builder.query({
      query: (queries) => {
        return {
          url: "/dashboard/salesAnalytics",
          params: removeEmptyKeys(queries),
        };
      },
      providesTags: ["Revenue"],
    }),
  }),
});

export const { useGetRevenueListQuery } = revenueApiSlice;
