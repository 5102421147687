import * as yup from 'yup';

export const TypesSchema = yup.object().shape({
  name: yup.string().trim().required('Required'),
  imgUrl: yup.string().trim().required('Required'),
  isFeatured: yup.boolean(),
  metaData: yup.object().shape({
    title: yup.string().trim(),
    keywords: yup.string().trim(),
    description: yup.string().trim(),
  }),
});

export const UpdateTypesSchema = yup.object().shape({
  name: yup.string().trim().required('Required'),
  imgUrl: yup.string().trim().required('Required'),
  isFeatured: yup.boolean(),
  metaData: yup.object().shape({
    title: yup.string().trim(),
    keywords: yup.string().trim(),
    description: yup.string().trim(),
  }),
});