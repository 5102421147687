import Cookies from 'js-cookie';

const AUTH_COOKIE_NAME = 'auth';

const saveAuthCookie = (auth) => {
  Cookies.set(AUTH_COOKIE_NAME, JSON.stringify(auth), {
    expires: 10800000,
  });
};

const getAuthCookie = () => {
  const auth = Cookies.get(AUTH_COOKIE_NAME);
  return auth && JSON.parse(auth);
};

const removeAuthCookie = () => {
  Cookies.remove(AUTH_COOKIE_NAME);
};

const saveAuthToLocal = (loginDetails) => {
  if(typeof localStorage != "undefined" && localStorage){
    localStorage.setItem("loginDetails", JSON.stringify(loginDetails))
  }
};

const saveToLocal = ( name, data) => {
  if(typeof localStorage != "undefined" && localStorage){
    localStorage.setItem( name, JSON.stringify(data))
  }
};

const getAuthFromLocal = () => {
  if (typeof localStorage != "undefined" && localStorage?.loginDetails) {
    const auth = JSON.parse(localStorage.loginDetails);
    return auth && auth;
  }
};
const getFromLocal = (name) => {
  if (typeof localStorage != "undefined" && localStorage?.loginDetails) {
    const data = JSON.parse(localStorage.getItem(name));
    return data && data;
  }
};

const removeAuthFromLocal = () => {
  if(typeof localStorage != "undefined" && localStorage){
    localStorage.removeItem("loginDetails")
  }
};

const removeFromLocal = (name) => {
  if(typeof localStorage != "undefined" && localStorage){
    localStorage.removeItem(name)
  }
};


export { saveAuthCookie, saveAuthToLocal, getAuthCookie, getAuthFromLocal, saveToLocal, getFromLocal, removeAuthFromLocal, removeFromLocal, removeAuthCookie };
