import AdminLayout from "../../components/organisms/adminLayout";
import DataGridBox from "../../components/organisms/dataGrid";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useGetProductQuery } from "../../api/product";
import { OrderType, ProductType } from "../../types/types";
import {
  Check,
  CurrencyRupee,
  Money,
  PriorityHigh,
  Replay,
  ShoppingCartOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { CircularProgressbarComponents } from "../../components/molecules/circularProgress";
import StatusCard from "../../components/molecules/StatusCard";
import TableChart from "../../components/molecules/tableChart";
import { useGetOrderQuery } from "../../api/order";
import TopBar from "../../components/atoms/Topbar";
import {
  useGetAnalyticsQuery,
  useGetMonthlyAnalyticsQuery,
} from "../../api/analytics";
import { OrderModal } from "../../components/molecules/OrderModal";

function ProductPage() {
  const [isEdit, setEdit] = useState(false);
  const [currentItem, setCurrentItem] = useState<OrderType>();
  const [selected, setSelected] = useState("all");
  const [topProductRows, setTopProductRows] = useState<GridRowsProp>([]);
  const [orderRows, setOrderRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>();
  const [salesProgress, setSalesProgress] = useState(0);
  const [changeForSales, setChangeForSales] = useState(0);
  const [changedSalesRevenue, setChangedSalesRevenue] = useState(0);
  const [changedSalesProducts, setChangedSalesProducts] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [paginationModel2, setPaginationModel2] = useState({
    pageSize: 25,
    page: 0,
  });

  const [value, setValue] = useState({
    totalSalesRevenue: 0,
    totalProductSold: 0,
    totalPrevSalesRevenue: 0,
    totalPrevProductSold: 0,
    monthStatistics: [],
    prevQuarter: 0,
    currentQuarter: 0,
    prevDayStats: 0,
    todayStats: 0,
    salesProgress: 0,
  });

  const topProductColumns: GridColDef[] = [
    { field: "srno", headerName: "Sr no.", width: 80, editable: false },
    { field: "name", headerName: "Name", width: 200, editable: false },
    { field: "qty", headerName: "Inventory", width: 100, editable: false },
    { field: "price", headerName: "Price", width: 100, editable: false },
    {
      field: "img",
      headerName: "Thumb",
      width: 200,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => (
        <img
          // onClick={handleThumbClick(`${params?.row?.img}`)}
          src={params?.row?.img}
          alt={params?.row?.img}
          style={{ width: "auto", height: "100%", padding: "10px" }} // adjust width and height as needed
        />
      ),
    },
    {
      field: "stock",
      type: "stock",
      headerName: "Stock",
      width: 180,
      cellClassName: "stock",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        return [
          <GridActionsCellItem
            icon={
              <>
                {params?.row?.isHigh ? (
                  <Check
                    className={`text-white bg-green-500 rounded-full p-0.5`}
                  />
                ) : (
                  <PriorityHigh
                    className={`text-white bg-red-500 rounded-full p-0.5`}
                  />
                )}
              </>
            }
            label="stock"
            color="inherit"
          />,
        ];
      },
    },
  ];

  const orderColumns: GridColDef[] = [
    { field: "srNo", headerName: "srNo", width: 100, editable: false },
    { field: "orderID", headerName: "OrderId", width: 150, editable: false },
    {
      field: "customerName",
      headerName: "Customer",
      width: 150,
      editable: false,
    },
    { field: "status", headerName: "status", width: 150, editable: false },
    {
      field: "paymentStatus",
      headerName: "Payment Status",
      width: 150,
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 180,
      cellClassName: "actions",
      getActions: ({ id }: GridRowParams<any>) => {
        return [
          <GridActionsCellItem
            icon={<VisibilityOutlined className="text-blue-400" />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const {
    refetch: analytics,
    data: analyticsData,
    isSuccess: isAnalyticsSuccess,
    isError: isAnalyticsError,
    isFetching: isAnalyticsFetching,
  } = useGetAnalyticsQuery({
    type: selected,
  });

  const {
    refetch: monthlyAnalytics,
    data: monthlyAnalyticsdata,
    isSuccess: isMonthlyAnalyticsSuccess,
    isError: isMonthlyAnalyticsError,
    isFetching: isMonthlyAnalyticsFetching,
  } = useGetMonthlyAnalyticsQuery({});

  const {
    data: OrderData,
    isFetching: isOrderIsFetching,
    isSuccess: isOrderSuccess,
  } = useGetOrderQuery({
    pageSize: paginationModel2.pageSize,
    pageNo: paginationModel2.page + 1,
  });

  const {
    data: ProductData,
    isLoading: isProductLoading,
    isSuccess: isProductSuccess,
  } = useGetProductQuery({
    pageSize: paginationModel.pageSize,
    pageNo: paginationModel.page + 1,
  });

  const salesData = analyticsData?.data;
  const monthlyData = monthlyAnalyticsdata?.data;

  useEffect(() => {
    const newChangedSalesRevenue = (
      ((value?.totalPrevSalesRevenue - value?.totalSalesRevenue) * 100) /
      value?.totalPrevSalesRevenue
    ).toFixed(0);

    const newChangedSalesProducts = (
      ((value?.totalPrevProductSold - value?.totalProductSold) * 100) /
      value?.totalPrevProductSold
    ).toFixed(0);

    setChangedSalesRevenue(parseInt(newChangedSalesRevenue));
    setChangedSalesProducts(parseInt(newChangedSalesProducts));
  }, [
    value?.totalPrevProductSold,
    value?.totalPrevSalesRevenue,
    value?.totalProductSold,
    value?.totalSalesRevenue,
  ]);

  useEffect(() => {
    const prevQuater =
      parseInt(monthlyData?.quarterData[0]?.prevMonthsStats[0]?.amount) || 100;
    const currQuater =
      parseInt(monthlyData?.quarterData[0]?.currentMonthsStats[0]?.amount) || 0;
    const prevDay =
      parseInt(monthlyData?.dailyStatistics[0]?.prevDayStats[0]?.amount) || 100;
    const currDay =
      parseInt(monthlyData?.dailyStatistics[0]?.todayStats[0]?.amount) || 0;
    setSalesProgress(
      ((prevQuater < currQuater ? currQuater : prevQuater - currQuater) * 100) /
        prevQuater
    );

    setChangeForSales(
      ((prevDay < currDay ? currDay : prevDay - currDay) * 100) / prevDay
    );
  }, [
    monthlyData?.quarterData[0]?.currentMonthsStats[0]?.amount,
    monthlyData?.dailyStatistics[0]?.prevDayStats[0]?.amount,
    monthlyData?.quarterData[0]?.prevMonthsStats[0]?.amount,
    monthlyData?.dailyStatistics[0]?.todayStats[0]?.amount,
  ]);

  useEffect(() => {
    setValue({
      ...value,
      monthStatistics: monthlyData?.monthStatistics,
      prevQuarter: monthlyData?.quarterData[0]?.prevMonthsStats[0]?.amount,
      currentQuarter:
        monthlyData?.quarterData[0]?.currentMonthsStats[0]?.amount,
      prevDayStats: monthlyData?.dailyStatistics[0]?.prevDayStats[0]?.amount,
      todayStats: monthlyData?.dailyStatistics[0]?.todayStats[0]?.amount,
    });
  }, [monthlyAnalyticsdata]);

  useEffect(() => {
    setValue({
      ...value,
      totalProductSold: salesData?.totalSalesData?.totalProducts,
      totalSalesRevenue: salesData?.totalSalesData?.totalRevenue,
      totalPrevProductSold: salesData?.totalPrevSalesData?.totalProducts,
      totalPrevSalesRevenue: salesData?.totalPrevSalesData?.totalRevenue,
    });
  }, [analyticsData]);

  useEffect(() => {
    if (ProductData) {
      setTopProductRows(
        ProductData?.data?.data?.map((item: ProductType, index: number) => ({
          srno: index + paginationModel.page * paginationModel.pageSize + 1,
          id: item._id,
          name: item.title,
          img: item.imgUrl,
          qty: item.inventory,
          price: item.price.salePrice,
          isHigh: item.inventory > 99 ? true : false,
        }))
      );
    }
  }, [isProductSuccess, ProductData]);

  useEffect(() => {
    if (OrderData) {
      console.log(OrderData?.data?.data);
      setOrderRows(
        OrderData?.data?.data?.map((item: OrderType, index: number) => ({
          id: item?._id,
          status: item?.orderStatus,
          customerName:
            item?.customer?.firstName + " " + item?.customer?.lastName,
          paymentStatus: item?.paymentStatus,
          orderID: item?.orderID,
          srNo: index + paginationModel2.page * paginationModel2.pageSize + 1,
        }))
      );
    }
  }, [isOrderSuccess, OrderData]);

  const handleEditClick = (id: GridRowId) => () => {
    setEdit(true);
    setCurrentItem(
      OrderData?.data?.data?.filter((item: OrderType) => item._id === id)[0]
    );
  };

  return (
    <AdminLayout>
      <h1 className="mb-4 text-[2rem] font-semibold">Dashboard</h1>

      <TopBar selected={selected} setSelected={setSelected} />
      <div className="flex w-full mb-4 flex-wrap gap-4">
        {value?.totalSalesRevenue ? (
          <>
            <StatusCard
              icon={<Money className="text-[#0d6dfd]" />}
              iconbg={"bg-[#0d6dfd40]"}
              title={"Total Revenue"}
              value={salesData?.totalSalesData?.totalRevenue}
              products={false}
              change={changedSalesRevenue}
            />
            <StatusCard
              icon={<ShoppingCartOutlined className="text-[#0d894f]" />}
              iconbg={"bg-[#cfe7dc]"}
              title={"Total Sales"}
              value={salesData?.totalSalesData?.totalProducts}
              products={true}
              change={changedSalesProducts}
            />
          </>
        ) : (
          <>
            <StatusCard
              icon={<CurrencyRupee className="text-[#0d6dfd]" />}
              iconbg={"bg-[#0d6dfd40]"}
              title={"Total Revenue"}
              value={0}
              products={false}
              change={0}
            />
            <StatusCard
              icon={<ShoppingCartOutlined className="text-[#0d894f]" />}
              iconbg={"bg-[#cfe7dc]"}
              title={"Total Sales"}
              value={0}
              products={true}
              change={0}
            />
          </>
        )}
        <StatusCard
          icon={<Replay className="text-[#FA3434]" />}
          title={"Returns"}
          iconbg={"bg-[#FA343440]"}
          value={0}
          products={false}
          change={0}
        />
      </div>

      <div className="w-full flex my-12 gap-3 ">
        <CircularProgressbarComponents
          todayStats={
            monthlyData?.dailyStatistics[0]?.todayStats[0]?.amount || 0
          }
          prevDayStats={
            monthlyData?.dailyStatistics[0]?.prevDayStats[0]?.amount || 0
          }
          currentQuarter={
            monthlyData?.quarterData[0]?.currentMonthsStats[0]?.amount || 0
          }
          salesProgress={salesProgress || 0}
          changeForSales={changeForSales || 0}
        />
        <div className="w-[65%] border rounded-lg p-4">
          {monthlyData?.monthStatistics && (
            <TableChart data={monthlyData?.monthStatistics} />
          )}
        </div>
      </div>

      <div className="topSelling my-8">
        <p className="text-lg font-semibold mb-2">Top Selling Product</p>
        <DataGridBox
          checkboxSelection={false}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          columns={topProductColumns}
          initialRows={topProductRows}
          qty={ProductData?.data?.totalCount}
          setRowModesModel={setRowModesModel}
        />
      </div>

      <div className="topSelling my-8">
        <p className="text-lg font-semibold mb-2">Recent Orders</p>
        <DataGridBox
          checkboxSelection={false}
          columns={orderColumns}
          initialRows={orderRows}
          rowModesModel={rowModesModel}
          paginationModel={paginationModel2}
          setPaginationModel={setPaginationModel2}
          qty={OrderData?.data?.totalCount}
        />
      </div>
      {isEdit && (
        <OrderModal
          item={currentItem}
          open={isEdit}
          handleClose={() => setEdit(false)}
        />
      )}
    </AdminLayout>
  );
}

export default ProductPage;
