import { configureStore } from "@reduxjs/toolkit";

import apiSlice from "./apiSlice";
import authReducer from "../api/auth/authSlice";
import storeReducer from "../api/store/storeSlice";
import cartReducer from "./cartSlice";
import { bulkDownloadApiSlice } from "../api/bulkUpload";

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [bulkDownloadApiSlice.reducerPath]: bulkDownloadApiSlice.reducer,
    auth: authReducer,
    cart: cartReducer,
    data: storeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      apiSlice.middleware,
      bulkDownloadApiSlice.middleware
    ),
});

export default store;
