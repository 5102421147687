interface props {
  selected: string;
  setSelected: Function;
}

export default function TopBar({ selected, setSelected }: props) {
  return (
    <div className="justify-between items-stretch flex w-full gap-5 max-md:max-w-full  max-md:flex-wrap  mb-6">
      <div className="items-start border border-[color:var(--neutral-gray-gray-100,#E0E2E7)] bg-white flex justify-between  m-1 rounded-lg border-solid max-md:max-w-full max-md:flex-wrap max-md:justify-center cursor-pointer">
        <div
          onClick={() => setSelected("all")}
          className={`text-sm  leading-5  whitespace-nowrap  justify-center items-stretch   rounded-md place-self-center px-4 py-2
          hover:bg-pink-100 hover:font-semibold hover:text-indigo-800
          ${
            selected === "all"
              ? "bg-pink-100 font-semibold text-indigo-800 "
              : "text-gray-500"
          }`}
        >
          All Time
        </div>
        <div
          onClick={() => setSelected("year")}
          className={`text-sm  leading-5  whitespace-nowrap justify-center items-stretch place-self-center rounded-md px-4 py-2
          hover:bg-pink-100 hover:font-semibold hover:text-indigo-800
           ${
             selected === "year"
               ? "bg-pink-100 font-semibold text-indigo-800 "
               : "text-gray-500"
           }`}
        >
          12 Months
        </div>
        <div
          onClick={() => setSelected("month")}
          className={`text-sm  leading-5  whitespace-nowrap justify-center items-stretch place-self-center rounded-md px-4 py-2 hover:bg-pink-100 hover:font-semibold hover:text-indigo-800 ${
            selected === "month"
              ? "bg-pink-100 font-semibold text-indigo-800 "
              : "text-gray-500"
          }`}
        >
          30 Days
        </div>
        <div
          onClick={() => setSelected("week")}
          className={`text-sm  leading-5  whitespace-nowrap justify-center items-stretch place-self-center rounded-md px-4 py-2 hover:bg-pink-100 hover:font-semibold hover:text-indigo-800 ${
            selected === "week"
              ? "bg-pink-100 font-semibold text-indigo-800 "
              : "text-gray-500"
          }`}
        >
          7 Days
        </div>
        <div
          onClick={() => setSelected("day")}
          className={`text-sm  leading-5  whitespace-nowrap justify-center items-stretch  place-self-center px-4 py-2 rounded-md hover:bg-pink-100 hover:font-semibold hover:text-indigo-800 ${
            selected === "day"
              ? "bg-pink-100 font-semibold text-indigo-800 "
              : "text-gray-500"
          }`}
        >
          24 Hour
        </div>
      </div>
      {/* <div className="items-stretch flex justify-between gap-4">
        <div className="justify-between items-stretch border border-[color:var(--neutral-gray-gray-100,#E0E2E7)] bg-white flex gap-2 px-3.5 py-2.5 rounded-lg border-solid place-self-center">
          <img
            loading="lazy"
            src="/svgs/calendar.svg"
            className="aspect-square object-contain object-center w-5 overflow-hidden shrink-0 max-w-full"
            alt="err"
          />
          <div className="text-gray-500 text-sm font-medium leading-5  grow whitespace-nowrap">
            Select Dates
          </div>
        </div>
        <div className="justify-between items-stretch bg-pink-600 flex gap-1 px-3.5 py-2.5 rounded-lg place-self-center">
          <img
            loading="lazy"
            alt="err"
            src="/svgs/pluswhite.svg"
            className="aspect-square object-contain object-center w-5 overflow-hidden shrink-0 max-w-full"
          />
          <div className="text-white text-sm font-semibold leading-5  grow whitespace-nowrap">
            Add Product
          </div>
        </div>
      </div> */}
    </div>
  );
}
