import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getManufacturer: builder.query({
      query: (queries) => {
        return {
          url: '/manufacturer',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['Manufacturer']
    }),
    createManufacturer: builder.mutation({
      query: (credentials) => ({
        url: "/manufacturer",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Manufacturer']
    }),
    updateManufacturer: builder.mutation({
      query: (credentials) => ({
        url: `/manufacturer/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['Manufacturer']
    }),
    bulkUpdateManufacturer: builder.mutation({
      query: (credentials) => ({
        url: `/manufacturer/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Manufacturer']
    }),
    deleteManufacturer: builder.mutation({
      query: (credentials) => ({
        url: `/manufacturer/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Manufacturer']
    }),
    hardDeleteManufacturer: builder.mutation({
      query: (credentials) => ({
        url: `/manufacturer/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Manufacturer']
    }),
  }),
});

export const {
  useGetManufacturerQuery,
  useCreateManufacturerMutation,
  useUpdateManufacturerMutation,
  useBulkUpdateManufacturerMutation,
  useDeleteManufacturerMutation,
  useHardDeleteManufacturerMutation
} = authApiSlice;
