import { Search } from "@mui/icons-material";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { logoutFilterHandler } from "../../api/store/storeActions";

interface props {
  setRegex?: Function;
  setPageNo?: Function;
  regex?: string;
  placeholder?: string;
}

function SearchBar({ setRegex, setPageNo, regex, placeholder }: props) {

  const dispatch = useDispatch<any>();
  function regexSet(e: { target: { value: string; }; }) {
    dispatch(logoutFilterHandler());
    setPageNo && setPageNo(1)
    setRegex && setRegex(e.target.value)
  }

  const debouncedOnChange = debounce(regexSet, 300);

  return (
    <div className="w-2/6 min-w-[15rem] h-[5vh] min-h-[2.5rem] overflow-hidden bg-white border rounded-xl my-auto flex items-center justify-between px-4">
      <input
        type="text"
        className="border-none w-5/6 active:outline-none focus-visible:outline-none"
        onChange={debouncedOnChange}
        defaultValue={regex}
        placeholder={placeholder? placeholder : "Seached for.."}
      />
      <Search className="text-black" style={{ fontSize: "1.8rem" }} />
    </div>
  );
}

export default SearchBar;
