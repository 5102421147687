import { useLocation, useNavigate } from "react-router-dom";
import { ButtonV4 } from "../atoms/button";
import SearchBar from "../atoms/searchBar";
import { getAuthFromLocal, removeAuthFromLocal } from "../../utils/storage";
import { useEffect } from "react";
import { authDetails, loginHandler } from "../../api/auth/authActions";
import { useGetCustomerQuery } from "../../api/users/users";
import { useDispatch } from "react-redux";

interface props {
  setRegex: Function;
  setPageNo: Function;
  regex: string;
}

export function Header({ setRegex, setPageNo, regex }: props) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const { pathname } = location;

  function navigation() {
    navigate("/admin");
  }

  function navigation2() {
    navigate("/signin");
  }

  let data: authDetails = getAuthFromLocal();

  const { refetch, data: userData } = useGetCustomerQuery({});

  useEffect(() => {
    console.log("refetching");
    refetch();
    if (userData) {
      console.log("refetching2");
      // let authDetails = {
      //   accessToken: userData.data.authToken,
      //   userId: userData.data._id,
      //   email: userData.data.email,
      //   fullName: userData.data.fullName,
      //   active: userData.data.active,
      //   isEmailVerified: userData.data.isEmailVerified,
      //   registrationStatus: userData.data.registrationStatus,
      //   roles: userData.data.roles[0],
      // };
      // dispatch(loginHandler(authDetails));
    }
    // !data && navigate("/signin");
  }, [pathname]);

  return (
    <div className="sticky top-0 w-full h-[10vh] overflow-hidden bg-white px-[2vw] py-[2vh] border-b-2 flex justify-between z-50">
      <img
        src="/img/logo.png"
        alt="logo"
        className="h-auto w-1/6 min-w-[10rem] rounded-full"
      />
      <div className="flex w-max gap-2">
        {userData?.data?.roles[0] !== "user" && (
          <ButtonV4 color="blue" setActive={navigation}>
            Admin Dashboard
          </ButtonV4>
        )}
        <SearchBar setRegex={setRegex} setPageNo={setPageNo} regex={regex} />
      </div>
    </div>
  );
}

export function AdminHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const { pathname } = location;

  // const { refetch, data: userData } = useGetUserQuery({});

  let data: authDetails = getAuthFromLocal();

  useEffect(() => {
    // refetch();
    // if (userData) {
    //   let authDetails = {
    //     userId: userData.data._id,
    //     email: userData.data.email,
    //     fullName: userData.data.fullName,
    //     active: userData.data.active,
    //     isEmailVerified: userData.data.isEmailVerified,
    //     registrationStatus: userData.data.registrationStatus,
    //     roles: userData.data.roles[0],
    //   };
    //   dispatch(loginHandler(authDetails));
    // }

    !data && navigate("/signin");

    function logoutHandler() {
      removeAuthFromLocal();
      navigate("/signin");
    }

    if (data?.roles) {
      data?.roles !== "admin" && logoutHandler();
    }
  }, [pathname]);

  return (
    <div className="sticky top-0 w-full h-[10vh] overflow-hidden bg-white px-[3vw] py-[2vh] flex justify-between z-50">
      <img
        src="/img/logo.png"
        alt="logo"
        className="h-auto w-max min-w-max rounded-full"
      />
      <div className="flex w-max gap-2"></div>
    </div>
  );
}
