import { createSlice } from "@reduxjs/toolkit";
import { removeAuthFromLocal, saveAuthToLocal } from "../../utils/storage";

const initialAuthState = {
  accessToken: '',
  userId: '',
  email: '',
  phone: '',
  fullName: '',
  firstName: '',
  lastName: '',
  active: false,
  countryCode: '',
  isEmailVerified: false,
  isPhoneVerified: false,
  registrationStatus: ''
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {

    login(state, action) {
      saveAuthToLocal(action.payload);
      state = action.payload;
    },

    logout(state) {
      state = initialAuthState;
      removeAuthFromLocal();
    },

  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
