import AdminLayout from "../../components/organisms/adminLayout";
import DataGridBox from "../../components/organisms/dataGrid";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModesModel,
  GridRowsProp,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AdminPageFilters from "../../components/organisms/adminPageFilters";
import { DeletePopup } from "../../components/molecules/DeleteModal";
import snackbar from "../../api/snackbar/useSnackbar";
import {
  useBulkUpdateCustomersMutation,
  useDeleteCustomerMutation,
  useGetCustomerlistQuery,
  useHardDeleteCustomersMutation,
} from "../../api/users/users";
import { Check, Close } from "@mui/icons-material";
import { UserModel } from "../../components/molecules/UserModal";

interface Item {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isBlocked: boolean;
}

function UsersPage() {
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [isDelete, setDelete] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [regex, setRegex] = React.useState<string>("");
  const [sortType, setSortType] = React.useState<string>("createdAt");
  const [updateType, setUpdateType] = React.useState<string>("createdAt");
  const [currentId, setCurrentId] = React.useState<GridRowId>("");
  const [currentItem, setCurrentItem] = React.useState<Item>({
    _id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    isBlocked: true,
  });
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>();
  const [selectedRowIds, setSelectedRowIds] = React.useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  const {
    data: CustomersData,
    isFetching: CustomersIsFetching,
    isSuccess: CustomersSuccess,
  } = useGetCustomerlistQuery({
    fullName: regex,
    pageSize: paginationModel.pageSize,
    pageNo: paginationModel.page + 1,
    sortType: sortType,
    sortOrder: -1,
  });

  const [
    bulkUpdateData,
    {
      data: bulkUpdatedData,
      isLoading: isbulkUpdatedLoading,
      isSuccess: isbulkUpdatedSuccess,
    },
  ] = useBulkUpdateCustomersMutation({});

  const [
    deleteCustomer,
    { isLoading: deleteIsLoading, isSuccess: deleteSuccess },
  ] = useDeleteCustomerMutation({});

  const [
    hardDeleteCustomer,
    { isLoading: hardDeleteIsLoading, isSuccess: hardDeleteSuccess },
  ] = useHardDeleteCustomersMutation({});

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 100, editable: false },
    { field: "name", headerName: "Name", width: 200, editable: false },
    { field: "email", headerName: "Email", width: 200, editable: false },
    { field: "phone", headerName: "Phone", width: 200, editable: false },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 180,
      cellClassName: "actions",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon className="text-blue-400" />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon className="text-red-400" />}
            label="Delete"
            onClick={handleDeleteClick(params.row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <>
                {params?.row?.isBlocked ? (
                  <Close
                    className={`text-white bg-yellow-500 rounded-full p-0.5`}
                  />
                ) : (
                  <Check
                    className={`text-white bg-green-500 rounded-full p-0.5`}
                  />
                )}
              </>
            }
            label="Block"
            onClick={handleSoftDelete(params.row.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    if (CustomersSuccess && CustomersData) {
      setRows(
        CustomersData?.data?.data.map((item: Item) => ({
          id: item?._id,
          name: item?.firstName + " " + item?.lastName,
          email: item?.email,
          phone: item?.phone,
          isBlocked: item.isBlocked,
        }))
      );
    }
  }, [CustomersData, CustomersSuccess]);

  const handleSelectionChange = (newSelection: GridRowId[]) => {
    console.log(newSelection);
    setSelectedRowIds(newSelection);
  };

  const handleEditClick = (id: GridRowId) => () => {
    setEdit(true);
    setCurrentId(id);
    console.log(
      CustomersData?.data?.data.filter((item: Item) => item._id === id)[0]
    );
    setCurrentItem(
      CustomersData?.data?.data.filter((item: Item) => item._id === id)[0]
    );
    console.log(
      CustomersData?.data?.data?.filter((item: Item) => item._id === id)[0]
        ?.roles[0]
    );
    console.log(id);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDelete(true);
    setCurrentId(id);
    console.log(id);
  };

  const handleSoftDelete = (id: GridRowId) => () => {
    console.log("id is ", id);

    deleteCustomer({ id: id })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setCurrentId("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  };

  const handleDelete = () => {
    hardDeleteCustomer({ id: currentId })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setDelete(false);
        setCurrentId("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setDelete(false);
      });
  };

  function bulkUpdate() {
    console.log(updateType);
    let data;
    if (updateType === "delete") {
      data = { active: false };
    }
    bulkUpdateData({ id: selectedRowIds, data })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setUpdateType("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setUpdateType("");
      });
  }

  return (
    <AdminLayout>
      <AdminPageFilters
        name="Customers"
        qty={CustomersData?.data?.totalCount}
        // setAddNew={handleCreateClick}
        setSortType={setSortType}
        setRegex={setRegex}
        bulkUpdate={bulkUpdate}
        setUpdateType={setUpdateType}
        regex={regex}
      />
      <DataGridBox
        checkboxSelection={true}
        columns={columns}
        initialRows={rows}
        rowModesModel={rowModesModel}
        selectedRowIds={selectedRowIds}
        paginationModel={paginationModel}
        setRowModesModel={setRowModesModel}
        qty={CustomersData?.data?.totalCount}
        setPaginationModel={setPaginationModel}
        onSelectionModelChange={handleSelectionChange}
      />
      {isDelete && (
        <DeletePopup
          open={isDelete}
          handleClose={() => setDelete(false)}
          handleDelete={handleDelete}
        />
      )}
      {isEdit && (
        <UserModel
          open={isEdit}
          item={currentItem}
          handleClose={() => setEdit(false)}
        />
      )}
    </AdminLayout>
  );
}

export default UsersPage;
