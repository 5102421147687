import { useLocation } from "react-router-dom";
import { ButtonV1 } from "../atoms/button";
import SearchBar from "../atoms/searchBar";
import { ChangeEvent, useRef, useState } from "react";
import { EditModal } from "../molecules/EditModal";
import snackbar from "../../api/snackbar/useSnackbar";
import {
  useBulkExportMutation,
  useBulkImportMutation,
} from "../../api/bulkUpload";
import { GridRowId } from "@mui/x-data-grid";

interface Props {
  qty: number;
  name: string;
  regex?: string;
  placeholder?: string;
  setAddNew?: Function;
  setSortType?: Function;
  setUpdateType?: Function;
  bulkUpdate?: Function;
  bulkUpdateStatus?: Function;
  setRegex?: Function;
  selectedRowIds?: GridRowId[];
  tabValue?: number;
  selectDate?: {
    fromDate?: string | Date;
    toDate?: string | Date;
  };
  setSelectDate?: Function;
}

export default function AdminPageFilters({
  qty,
  name,
  setAddNew,
  setRegex,
  setSortType,
  setUpdateType,
  bulkUpdate,
  bulkUpdateStatus,
  regex,
  placeholder,
  selectedRowIds,
  tabValue,
  selectDate,
  setSelectDate,
}: Props) {
  const location = useLocation();
  const { pathname } = location;
  const [isImport, setIsImport] = useState(false);
  const [isExport, setIsExport] = useState(false);
  const [file, setFile] = useState<any>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [
    createImport,
    { isLoading: createImportIsLoading, isSuccess: createImportSuccess },
  ] = useBulkImportMutation({});

  const [
    createExport,
    { isLoading: createExportIsLoading, isSuccess: createExportSuccess },
  ] = useBulkExportMutation({});

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFile(event?.target.files && event?.target?.files[0]);
  };

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (file) {
      const formData = new FormData();
      formData.append("files", file);

      createImport(formData)
        .unwrap()
        .then((res) => {
          setIsImport(false);
          console.log("formSubmitted");
          snackbar.success(res?.data);
        })
        .catch((error) => {
          setIsImport(false);
          console.log(error);
        });
    }
  }

  function handleExport() {
    createExport({
      query: {
        fromDate: selectDate?.fromDate,
        toDate: selectDate?.toDate,
        id: selectedRowIds?.length ? String(selectedRowIds) : "",
        shippingStatus:
          tabValue === 1 ? "print" : tabValue === 2 ? "handover" : "",
      },
    })
      .unwrap()
      .then((res) => {
        setIsExport(false);
        // const fileUrl = res?.data?.split("undefined")?.[1] ?? "";
        // console.log("Exported", res);
        snackbar.success("Downloading start soon");
        // const url = `https://infinite-green-backend.nfaml9.easypanel.host${fileUrl}`;
        window.open(res?.data, "_blank");
      })
      .catch((error) => {
        setIsImport(false);
        console.log(error);
      });
    setIsExport(false);
  }

  return (
    <div className="w-full">
      <div className="flex justify-between w-full pb-4 ">
        <p className="text-[2rem] font-semibold flex flex-col capitalize">
          {name}
          <span className="text-sm text-left"> Total Count: {qty}</span>
        </p>
        <div className="flex gap-4">
          {/* <div className="flex items-center">
            <h1 className="mr-4">Sort :</h1>
            <div className="w-max mt-1 mr-2 px-4 py-2 border rounded-xl">
              <select
                className="min-w-24 focus-visible:outline-none capitalize"
                onChange={(e) => setSortType && setSortType(e.target.value)}
              >
                <option value={"createdAt"}>Last created</option>
                {pathname === "/products" && (
                  <option value={"title"}>title</option>
                )}
                {pathname === "/orders" && (
                  <option value={"orderId"}>title</option>
                )}
                {pathname !== "/orders" && <option value={"name"}>name</option>}
              </select>
            </div>
          </div> */}
          <SearchBar
            setRegex={setRegex}
            regex={regex}
            placeholder={placeholder}
          />
        </div>
      </div>
      <div className="flex justify-between w-full">
        <div className="flex items-center mb-2">
          <div className="w-max mr-2 m-auto px-4 py-2 border rounded-xl">
            <select
              className="min-w-24 focus-visible:outline-none capitalize"
              onChange={(e) => setUpdateType && setUpdateType(e.target.value)}
            >
              <option></option>
              {pathname === "/products" && (
                <option value={"active"}>Activate</option>
              )}
              {pathname === "/products" && (
                <option value={"inactive"}>Deactivate</option>
              )}
              <option value={"delete"}>Delete</option>
            </select>
          </div>
          <div className="w-min">
            <ButtonV1 color="blue" setActive={() => bulkUpdate && bulkUpdate()}>
              Go
            </ButtonV1>
          </div>
          {selectedRowIds?.length && tabValue ? (
            <div className="w-min ml-2">
              <ButtonV1
                color="blue"
                setActive={() =>
                  bulkUpdateStatus &&
                  bulkUpdateStatus(
                    tabValue === 1 ? "handover" : tabValue === 2 ? "print" : ""
                  )
                }
              >
                {tabValue === 1 ? "Handover" : tabValue === 2 ? "Print" : null}
              </ButtonV1>
            </div>
          ) : null}
        </div>
        <div className="flex gap-4 w-min h-min">
          {pathname === "/orders" && (
            <div className="flex gap-2">
              <div className="flex gap-2 items-center">
                <input
                  className="border border-gray-400 rounded-lg p-2"
                  placeholder="From date"
                  type="datetime-local"
                  onChange={(e: any) =>
                    setSelectDate &&
                    setSelectDate({
                      ...selectDate,
                      fromDate: new Date(e.target.value).toISOString(),
                    })
                  }
                />
                -
                <input
                  className="border border-gray-400 rounded-lg p-2"
                  placeholder="To date"
                  type="datetime-local"
                  onChange={(e: any) =>
                    setSelectDate &&
                    setSelectDate({
                      ...selectDate,
                      toDate: new Date(e.target.value).toISOString(),
                    })
                  }
                />
              </div>
              <ButtonV1 color="blue" setActive={() => setIsExport(true)}>
                Export
              </ButtonV1>
            </div>
          )}
          {pathname === "/products" && (
            <ButtonV1 color="red" setActive={() => setIsImport(true)}>
              bulk Add
            </ButtonV1>
          )}
          {name !== "Customers" && name !== "Orders" && (
            <ButtonV1 color="blue" setActive={() => setAddNew && setAddNew()}>
              Add new
            </ButtonV1>
          )}
        </div>
        <EditModal open={isImport} handleClose={() => setIsImport(false)}>
          <form onSubmit={handleSubmit}>
            <input
              ref={fileInputRef}
              type="file"
              onChange={(e) => handleFileChange(e)}
              className="my-4 w-full"
            />
            <ButtonV1 color="blue" type="submit">
              upload
            </ButtonV1>
          </form>
        </EditModal>
        <EditModal open={isExport} handleClose={() => setIsExport(false)}>
          <p className="w-full text-center text-lg font-semibold my-6">
            You are going to <br />
            export all the Products
          </p>
          <div className="flex gap-4 px-4">
            <ButtonV1 color="red" setActive={() => setIsExport(false)}>
              Cancel
            </ButtonV1>
            <ButtonV1 color="blue" setActive={handleExport}>
              Export
            </ButtonV1>
          </div>
        </EditModal>
      </div>
    </div>
  );
}
