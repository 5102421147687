import "../../App.css";
import { ArrowUpward } from "@mui/icons-material";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar } from "react-circular-progressbar";

interface props {
  salesProgress: number;
  changeForSales: number;
  todayStats: number;
  prevDayStats: number;
  currentQuarter: number;
}

export function CircularProgressbarComponents({
  salesProgress,
  changeForSales,
  currentQuarter,
  todayStats,
  prevDayStats,
}: props) {
  console.log(salesProgress, changeForSales);

  return (
    <div className=" p-6 rounded-lg  border w-fit h-auto  shadow-md ">
      <div className="text-lg">Sales Progress </div>
      <div className="text-sm">This Quarter</div>
      <div className="pt-4 h-fit w-fit relative">
        <div className="max-w-full aspect-[2] overflow-hidden relative">
          <CircularProgressbar
            value={salesProgress}
            circleRatio={0.5}
            strokeWidth={5}
            styles={{
              root: {
                transform: "rotate(0.75turn)",
                padding: "0 20px",
                position: "relative",
              },
              path: {
                stroke: "green",
                strokeLinecap: "round",
              },
              trail: {
                stroke: "#C4C4C4",
                strokeLinecap: "round",
                strokeWidth: 2,
              },
            }}
          />
          <text className=" absolute top-2/3 text-2xl font-extrabold left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
            <tspan>
              {salesProgress === -Infinity
                ? "No Data"
                : `${salesProgress.toFixed(1)}%`}
            </tspan>
            <h1
              className={`my-2 mx-auto w-fit px-3 rounded-full text-[12px] text-center ${
                changeForSales > 0
                  ? "bg-[#E7F4EE] text-[#0D894F]"
                  : changeForSales !== -Infinity
                  ? "bg-[#FEEDEC] text-[#F04438]"
                  : "bg-[#F0F1F3] text-[#667085]"
              }`}
            >
              {changeForSales == -Infinity
                ? "No Data"
                : `${changeForSales.toFixed(2)}%`}
            </h1>
          </text>
        </div>
        <div className="text-sm leading-5 text-center text-[#667085]">
          You succeed earn{" "}
          <b className="text-[#000]">
            {todayStats - prevDayStats > 0
              ? ((todayStats - prevDayStats) / 1000).toFixed(2) + "k"
              : "0"}
          </b>{" "}
          {(todayStats - prevDayStats > 0) &&
            "today, its higher than yesterday"}
        </div>
        <div className="flex gap-6  mt-4 justify-center ">
          <div>
            <p className="text-xs text-[#667085] text-center">Revenue</p>
            <div>
              <div className="inline-flex text-lg font-semibold">
                {(currentQuarter > 0 ? (currentQuarter / 1000).toFixed(2) : 0) +
                  "k"}
              </div>
              <div className="inline-flex">
                <ArrowUpward />
              </div>
            </div>
          </div>
          <div>
            <p className="text-xs text-[#667085] text-center">Today</p>
            <div>
              <div className="inline-flex text-lg font-semibold">
                {(todayStats > 0 ? (todayStats / 1000).toFixed(2) : 0) + "k"}
              </div>
              <div className="inline-flex">
                <ArrowUpward />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
