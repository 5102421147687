import { authActions } from "./authSlice";
import snackbar from "../snackbar/useSnackbar";

import {
  saveAuthCookie,
  removeAuthCookie
} from "../../utils/storage";

export interface authDetails {
  accessToken?: string;
  userId: string;
  email?: string | null;
  phone?: string | null;
  fullName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  active: boolean,
  countryCode?: string,
  isEmailVerified?: boolean,
  isPhoneVerified?: boolean,
  registrationStatus: string
  roles: string
        
}
interface config {
  isSession: boolean;
}

// const checkLoginStatus = () => {
//   return (dispatch: (arg0: { payload: any; type: "auth/logout" | "auth/login"; }) => void) => {
//     const authDetails = getAuthFromLocal();
//     console.log("authDetails", authDetails);

//     if (authDetails) {
//       const accessExpired = checkTimeIsExpired(
//         authDetails.accessTokenExpirationTime
//       );

//       if (accessExpired) {
//         removeAuthCookie();
//         dispatch(authActions.logout());
//         snackbar.error("Session expired");
//         return;
//       }

//       dispatch(authActions.login(authDetails));
//       const autoLogoutTime = calculateRemainingTime(
//         authDetails.accessTokenExpirationTime
//       );
//       setTimeout(() => {
//         removeAuthCookie();
//         dispatch(authActions.logout());
//         snackbar.error("Session expired");
//       }, autoLogoutTime);

//       return;
//     }

//     removeAuthCookie();
//     dispatch(authActions.logout());
//   };
// };

const loginHandler = (authDetails: authDetails) => {
  return (dispatch: (arg0: { payload: any; type: "auth/logout" | "auth/login"; }) => void) => {

    // const accessTokenExpirationTime =
      // Date.now() + +`${process.env.NEXT_PUBLIC_ACCESS_TOKEN_EXPIRATION_TIME}`;

    saveAuthCookie(authDetails);
    
    dispatch(
      authActions.login(authDetails)
    );

    // const autoLogoutTime = calculateRemainingTime(accessTokenExpirationTime);

    // setTimeout(() => {
    //   removeAuthCookie();
    //   dispatch(authActions.logout());
    //   snackbar.error("Session expired");
    // }, autoLogoutTime);

  };
};

const logoutHandler = (config: config) => {

  return (dispatch: (arg0: { payload: undefined; type: "auth/logout"; }) => void) => {
    console.log("cleaned");
    removeAuthCookie();
    dispatch(authActions.logout());
    !config?.isSession && snackbar.error("Logged out");
  };
};

export { loginHandler, logoutHandler };
