import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logoutHandler } from "../api/auth/authActions";
import snackbar from "../api/snackbar/useSnackbar";
import { getAuthFromLocal } from "../utils/storage";
import Config from "../config/constants";

const baseQuery = fetchBaseQuery({
  baseUrl: Config.baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const data = getAuthFromLocal();
    const token = data?.accessToken;
    token && headers.set("authorization", `Bearer ${token}`);
    return headers;
  },
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    api.dispatch(logoutHandler({ isSession: true }));
    snackbar.error("Session expired");
  }

  return result;
};

const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    "User",
    "Product",
    "Order",
    "Return",
    "Category",
    "Types",
    "Brand",
    "Manufacturer",
    "AnalyticsTag",
    "Customer",
    "Revenue",
  ],
  endpoints: (builder) => ({}),
});

export default apiSlice;
