import * as Yup from "yup";

export const ProductSchema = Yup.object().shape({
  title: Yup.string().required("Required").trim(),
  description: Yup.string().required("Required").trim(),
  imgUrl: Yup.string().required("Required").trim(),
  imgGallery: Yup.array()
    .of(Yup.string().trim().required("Required"))
    .min(1, "One Image is required"),
  inventory: Yup.number().min(0, "Can't set below 0").required("Required"),
  applinks:Yup.string().url("Must be a valid URL").required('Must be a valid URL').trim(),
  productInfo: Yup.object().shape({
    brandId: Yup.string().required("Required").trim(),
    typeId: Yup.string().required("Required").trim(),
    dischargeCurrent: Yup.string().required("Required").trim(),
    chargeCurrent: Yup.string().required("Required").trim(),
    bmsType: Yup.string().required("Required").trim(),
    enclosure: Yup.string().required("Required").trim(),
    // packaging: Yup.string().required('Required').trim(),
    // supportActivations: Yup.string().required('Required').trim(),
    // weight: Yup.string().required('Required').trim(),
    // dimension: Yup.string().required('Required').trim(),
  }),
  // productType: Yup.object().shape({
  //   categoryId: Yup.string().required("Required").trim(),
  //   condition: Yup.string().required("Required").trim(),
  //   manufacturerId: Yup.string().required("Required").trim(),
  // }),
  price: Yup.object().shape({
    marketPrice: Yup.number().required("Required").min(1,"Can't be less than 1"),
    discount: Yup.number().required("Required"),
    salePrice: Yup.number().required("Required").min(1,"Can't be less than 1"),
    currency: Yup.string().required("Required"),
  }),
  attributes: Yup.object().shape({
    title: Yup.string().trim().required("Required"),
    keywords: Yup.string().trim().required("Required"),
    description: Yup.string().trim().required("Required"),
  }),
  fbTags:Yup.object().shape({
    id: Yup.string().required("Required").trim(),
    pg_retailer_id: Yup.string().required("Required").trim(),
    retailer_id: Yup.string().required("Required").trim(),
  })
});

export const UpdateProductSchema = Yup.object().shape({
  title: Yup.string().required("Required").trim(),
  description: Yup.string().required("Required").trim(),
  imgUrl: Yup.string().required("Required").trim(),
  imgGallery: Yup.array()
    .of(Yup.string().trim().required("Required"))
    .min(1, "One Image is required"),
  inventory: Yup.number().min(0, "Can't set below 0").required("Required"),
  applinks:Yup.string().url("Must be a valid URL").required("Required").trim(),
  productInfo: Yup.object().shape({
    brandId: Yup.string().required("Required").trim(),
    typeId: Yup.string()
    .required("Required")
    .typeError("Must be a string")
    .trim(),
    dischargeCurrent: Yup.string().trim(),
    chargeCurrent: Yup.string().trim(),
    bmsType: Yup.string().trim(),
    enclosure: Yup.string().trim(),
    packaging: Yup.string().trim(),
    supportActivations: Yup.string().trim(),
    weight: Yup.string(),
    dimension: Yup.string(),
  }),
  productType: Yup.object().shape({
    categoryId: Yup.string(),
    condition: Yup.string(),
    manufacturerId: Yup.string(),
  }),
  price: Yup.object().shape({
    marketPrice: Yup.number().min(1, "Can't set below 1").required("Required"),
    discount: Yup.number().min(1, "Can't set below 1").required("Required"),
    salePrice: Yup.number().min(1, "Can't set below 1").required("Required"),
    currency: Yup.string().required("Required"),
  }),
  attributes: Yup.object().shape({
    title: Yup.string().trim(),
    keywords: Yup.string().trim(),
    description: Yup.string().trim(),
  }),
  fbTags:Yup.object().shape({
    id: Yup.string().required("Required").trim(),
    pg_retailer_id: Yup.string().required("Required").trim(),
    retailer_id: Yup.string().required("Required").trim(),
  })
});
