import AdminLayout from "../../components/organisms/adminLayout";
import DataGridBox from "../../components/organisms/dataGrid";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModesModel,
  GridRowsProp,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";
import snackbar from "../../api/snackbar/useSnackbar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AdminPageFilters from "../../components/organisms/adminPageFilters";
import {
  useBulkUpdateTypesMutation,
  useDeleteTypesMutation,
  useHardDeleteTypesMutation,
  useGetTypesQuery,
  useUpdateTypesMutation,
} from "../../api/filters/types";
import { CategoryType } from "../../types/types";
import { DeletePopup } from "../../components/molecules/DeleteModal";
import {
  AddTypesModal,
  EditTypesModal,
} from "../../components/molecules/TypesModal";

function TypesPage() {
  const [currentId, setCurrentId] = React.useState<GridRowId>("");
  const [isEdit, setEdit] = React.useState(false);
  const [editItem, setEditItem] = React.useState<CategoryType>();
  const [isAddNew, setAddNew] = React.useState(false);
  const [isDelete, setDelete] = React.useState(false);
  const [regex, setRegex] = React.useState<string>("");
  const [sortType, setSortType] = React.useState<string>("createdAt");
  const [updateType, setUpdateType] = React.useState<string>("createdAt");
  const [sortOrder, setSortOrder] = React.useState<Number>(-1);
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [selectedRowIds, setSelectedRowIds] = React.useState<GridRowId[]>([]);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  const columns: GridColDef[] = [
    { field: "srno", headerName: "Sr no.", width: 80, editable: false },
    { field: "name", headerName: "Name", width: 250, editable: false },
    {
      field: "img",
      headerName: "Thumb",
      width: 250,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => (
        <img
          // onClick={handleThumbClick(`${params?.row?.img}`)}
          src={params?.row?.img}
          alt={params?.row?.img}
          style={{ width: "auto", height: "100%", padding: "10px" }} // adjust width and height as needed
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 180,
      cellClassName: "actions",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon className="text-blue-400" />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon className="text-red-400" />}
            label="Delete"
            onClick={handleDeleteClick(params.row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <p
                className={`flex items-center text-white ${
                  params?.row?.status === true
                    ? "bg-green-500"
                    : "bg-red-500"
                } rounded-full p-1 px-2 mr-1 text-xs capitalize`}
              >
                {params?.row?.status ? "Active" : "Inactive"}
              </p>
            }
            label="Unpublish"
            onClick={handleSoftDelete(params.row.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const {
    data: TypesData,
    isLoading: isTypesLoading,
    isSuccess: isTypesSuccess,
  } = useGetTypesQuery({
    title: regex,
    pageSize: paginationModel.pageSize,
    pageNo: paginationModel.page + 1,
    sortType: sortType,
    sortOrder: -1,
  });

  const [
    updateData,
    {
      data: updatedData,
      isLoading: isUpdatedLoading,
      isSuccess: isUpdatedSuccess,
    },
  ] = useUpdateTypesMutation({});

  const [
    bulkUpdateData,
    {
      data: bulkUpdatedData,
      isLoading: isbulkUpdatedLoading,
      isSuccess: isbulkUpdatedSuccess,
    },
  ] = useBulkUpdateTypesMutation({});

  const [
    deleteTypes,
    { isLoading: deleteIsLoading, isSuccess: deleteSuccess },
  ] = useDeleteTypesMutation({});

  const [
    hardDeleteTypes,
    { isLoading: hardDeleteIsLoading, isSuccess: hardDeleteSuccess },
  ] = useHardDeleteTypesMutation({});

  useEffect(() => {
    if (TypesData) {
      setRows(
        TypesData?.data?.data?.map((item: CategoryType, index: number) => ({
          srno: index + paginationModel.page * paginationModel.pageSize + 1,
          id: item._id,
          name: item.name,
          img: item.imgUrl,
          status: item.isFeatured,
        }))
      );
    }
    // console.log("TypesData", TypesData);
  }, [isTypesSuccess, TypesData, paginationModel]);

  const handleEditClick = (id: GridRowId) => () => {
    setEdit(true);
    const item = TypesData?.data?.data.filter(
      (item: CategoryType) => item?._id === id
    );
    setEditItem(item[0]);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDelete(true);
    setCurrentId(id);
    console.log(id);
  };

  function handleHardDelete() {
    hardDeleteTypes({ id: currentId })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || 'Success');
        setDelete(false);
        setCurrentId("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setDelete(false);
      });
  }

  const handleSoftDelete = (id: GridRowId) => () => {
    deleteTypes({ id: id })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || 'Success');
        setDelete(false);
        setCurrentId("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setDelete(false);
      });

    console.log(id);
  };

  const handleSelectionChange = (newSelection: GridRowId[]) => {
    console.log(newSelection);
    setSelectedRowIds(newSelection);
  };

  function bulkUpdate() {
    let data;
    if (updateType === "delete") {
      data = { active: false };
    } else if (updateType === "active") {
      data = { status: 'active' };
    } else {
      data = { status: 'inactive' };
    }

    bulkUpdateData({ id: selectedRowIds, data })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || 'Success');
        setUpdateType("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setUpdateType("");
      });
  }

  return (
    <AdminLayout>
      <AdminPageFilters
        name="Types"
        setAddNew={() => setAddNew(true)}
        qty={TypesData?.data?.totalCount}
        setRegex={setRegex}
        bulkUpdate={bulkUpdate}
        setUpdateType={setUpdateType}
        setSortType={setSortType}
        regex={regex}
      />
      <DataGridBox
          checkboxSelection={true}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        columns={columns}
        initialRows={rows}
        qty={TypesData?.data?.totalCount}
        rowModesModel={rowModesModel}
        selectedRowIds={selectedRowIds}
        setRowModesModel={setRowModesModel}
        onSelectionModelChange={handleSelectionChange}
      />
      {isEdit && (
        <EditTypesModal
          item={editItem}
          open={isEdit}
          handleClose={() => setEdit(false)}
        />
      )}
      {isAddNew && (
        <AddTypesModal open={isAddNew} handleClose={() => setAddNew(false)} />
      )}
      {isDelete && (
        <DeletePopup
          open={isDelete}
          handleClose={() => setDelete(false)}
          handleDelete={() => handleHardDelete()}
        />
      )}
    </AdminLayout>
  );
}

export default TypesPage;