import { createSlice } from "@reduxjs/toolkit";

const initialStoreState = {
  brand: [],
  category: [],
  manufacturer: []
};

const storeSlice = createSlice({
  name: "data",
  initialState: initialStoreState,
  reducers: {

    store(state, action) {
      const {
        brand,
        category,
        manufacturer
      } = action.payload;

      if (brand) {
        state.brand = brand;
      }
      if (category) {
        state.category = category;
      }
      if (manufacturer) {
        state.manufacturer = manufacturer;
      }
    },

    storeClean(state, action) {
      const {
        brand,
        category,
        manufacturer
      } = action.payload;
      if (brand) {
        state.brand = initialStoreState.brand;
      }
      if (category) {
        state.category = initialStoreState.category;
      }
      if (manufacturer) {
        state.manufacturer = initialStoreState.manufacturer;
      }
    },

    logout(state) {
      state.brand = initialStoreState.brand;
      state.category = initialStoreState.category;
      state.manufacturer = initialStoreState.manufacturer;
    },
  },
});

export const storeActions = storeSlice.actions;
export default storeSlice.reducer;
