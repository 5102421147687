import AdminLayout from "../../components/organisms/adminLayout";
import DataGridBox from "../../components/organisms/dataGrid";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import React, { useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AdminPageFilters from "../../components/organisms/adminPageFilters";
import {
  useBulkUpdateCategoryMutation,
  useCreateCategoryMutation,
  useDeleteCategoryMutation,
  useGetCategoryQuery,
  useUpdateCategoryMutation,
} from "../../api/filters/category";
import { DeletePopup } from "../../components/molecules/DeleteModal";
import { EditModal } from "../../components/molecules/EditModal";
import {
  FileInputField,
  InputFieldV2,
} from "../../components/atoms/inputField";
import { ButtonV1 } from "../../components/atoms/button";
import snackbar from "../../api/snackbar/useSnackbar";
import { SelectBox } from "../../components/atoms/selectBox";
import { useGetTypesQuery } from "../../api/filters/types";

interface Item {
  _id: string;
  name: string;
  imgUrl: string;
}

interface Item {
  _id: string;
  name: string;
  imgUrl: string;
  type: {
    _id: string;
    name: string;
  };
}

function CategoryPage() {
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [isDelete, setDelete] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [isAddNew, setAddNew] = React.useState(false);
  const [Category, setCategory] = React.useState("");
  const [regex, setRegex] = React.useState<string>("");
  const [sortType, setSortType] = React.useState<string>("createdAt");
  const [updateType, setUpdateType] = React.useState<string>("createdAt");
  const [image, setImage] = React.useState("");
  const [type, setType] = React.useState<{ id: string; title: string }>();
  const [currentId, setCurrentId] = React.useState<GridRowId>("");
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });

  const {
    data: CategoryData,
    isFetching: CategoryIsFetching,
    isSuccess: CategorySuccess,
  } = useGetCategoryQuery({
    name: regex,
    pageSize: paginationModel.pageSize,
    pageNo: paginationModel.page + 1,
    sortType: sortType,
    sortOrder: -1,
  });

  const {
    data: TypesData,
    isLoading: isTypesLoading,
    isSuccess: isTypesSuccess,
  } = useGetTypesQuery({
    pageSize: 10000,
    pageNo: 1,
    sortType: "name",
    sortOrder: 1,
  });

  console.log("🚀 ~ CategoryPage ~ TypesData:", TypesData);

  const [update, { isLoading: updateIsLoading, isSuccess: updateSuccess }] =
    useUpdateCategoryMutation({});

  const [
    bulkUpdateData,
    {
      data: bulkUpdatedData,
      isLoading: isbulkUpdatedLoading,
      isSuccess: isbulkUpdatedSuccess,
    },
  ] = useBulkUpdateCategoryMutation({});

  const [create, { isLoading: createIsLoading, isSuccess: createSuccess }] =
    useCreateCategoryMutation({});

  const [
    deleteCategory,
    { isLoading: deleteIsLoading, isSuccess: deleteSuccess },
  ] = useDeleteCategoryMutation({});

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 150, editable: false },
    { field: "name", headerName: "Name", width: 350, editable: false },
    {
      field: "img",
      headerName: "Thumb",
      width: 150,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => (
        <img
          // onClick={handleThumbClick(`${params?.row?.img}`)}
          src={params?.row?.image}
          alt={params?.row?.image}
          style={{ width: "auto", height: "100%", padding: "10px" }} // adjust width and height as needed
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 180,
      cellClassName: "actions",
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) => [
        <GridActionsCellItem
          icon={<EditIcon className="text-blue-400" />}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(
            params?.row?.id,
            params?.row?.image,
            params?.row?.name,
            params?.row?.type
          )}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<DeleteIcon className="text-red-400" />}
          label="Delete"
          onClick={handleDeleteClick(params?.row?.id)}
          color="inherit"
        />,
      ],
    },
  ];

  useEffect(() => {
    if (CategorySuccess && CategoryData) {
      setRows(
        CategoryData?.data?.data?.map((item: Item) => ({
          id: item._id,
          name: item.name,
          image: item.imgUrl,
          type: item.type,
        }))
      );
    }
  }, [CategoryData, CategoryIsFetching, CategorySuccess]);

  const handleCreateClick = () => {
    setAddNew(true);
    setImage("");
    setType(undefined);
  };

  const handleEditClick =
    (
      id: string,
      image: string,
      name: string,
      type: { _id: string; name: string }
    ) =>
    () => {
      setEdit(true);
      setCurrentId(id);
      setImage(image);
      setCategory(name);
      setType({
        id: type?._id || "",
        title: type?.name || "",
      });
      console.log(id);
    };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDelete(true);
    setCurrentId(id);
    console.log(id);
  };

  const [selectedRowIds, setSelectedRowIds] = React.useState<GridRowId[]>([]);

  const handleSelectionChange = (newSelection: GridRowId[]) => {
    console.log(newSelection);
    setSelectedRowIds(newSelection);
  };

  function handleCreate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    create({ name: Category, imgUrl: image, type: type?.id })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setAddNew(false);
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  }

  function handleUpdate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    update({
      id: currentId,
      body: { name: Category, imgUrl: image, type: type?.id },
    })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setEdit(false);
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  }

  function handleDelete() {
    deleteCategory({ id: currentId })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setDelete(false);
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setDelete(false);
      });
  }

  function bulkUpdate() {
    let data;
    if (updateType === "delete") {
      data = { active: false };
    }
    bulkUpdateData({ id: selectedRowIds, data })
      .unwrap()
      .then((res) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setUpdateType("");
      })
      .catch((error) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setUpdateType("");
      });
  }

  return (
    <AdminLayout>
      <AdminPageFilters
        name="Category"
        qty={CategoryData?.data?.totalCount}
        setAddNew={handleCreateClick}
        setRegex={setRegex}
        bulkUpdate={bulkUpdate}
        setUpdateType={setUpdateType}
        setSortType={setSortType}
        regex={regex}
      />
      <DataGridBox
        checkboxSelection={true}
        columns={columns}
        initialRows={rows}
        rowModesModel={rowModesModel}
        selectedRowIds={selectedRowIds}
        paginationModel={paginationModel}
        setRowModesModel={setRowModesModel}
        qty={CategoryData?.data?.totalCount}
        setPaginationModel={setPaginationModel}
        onSelectionModelChange={handleSelectionChange}
      />
      {isDelete && (
        <DeletePopup
          open={isDelete}
          handleClose={() => setDelete(false)}
          handleDelete={handleDelete}
        />
      )}
      {isEdit &&
        (isTypesLoading ? (
          <h1> Loading... </h1>
        ) : (
          <EditModal open={isEdit} handleClose={() => setEdit(false)}>
            <form className="flex flex-col" onSubmit={(e) => handleUpdate(e)}>
              <h1 className="text-lg font-medium mb-4 w-full">Edit Category</h1>
              <InputFieldV2
                name="title"
                lable="Name of Category"
                type="text"
                handleChange={setCategory}
                value={rows.find((item) => item.id === currentId)?.name}
              />
              <SelectBox
                lable="Type"
                handleChange={setType}
                options={TypesData?.data?.data.map((item: Item) => ({
                  id: item._id,
                  title: item.name,
                }))}
                value={type}
              />
              <FileInputField
                name="imgUrl"
                lable="Image"
                type="file"
                accept="image/*"
                setImage={setImage}
                image={[image]}
              />
              <ButtonV1 color="blue" type="submit">
                Update
              </ButtonV1>
            </form>
          </EditModal>
        ))}
      {isAddNew &&
        (isTypesLoading ? (
          <h1> Loading... </h1>
        ) : (
          <EditModal open={isAddNew} handleClose={() => setAddNew(false)}>
            <form className="flex flex-col" onSubmit={(e) => handleCreate(e)}>
              <h1 className="text-lg font-medium mb-4 w-full">Add Category</h1>
              <InputFieldV2
                name="title"
                lable="Name of Category"
                type="text"
                placeholder="Add new Category"
                handleChange={setCategory}
              />
              <SelectBox
                lable="Type"
                handleChange={setType}
                options={TypesData?.data?.data.map((item: Item) => ({
                  id: item._id,
                  title: item.name,
                }))}
                value={type}
              />
              <FileInputField
                name="imgUrl"
                lable="Image"
                type="file"
                accept="image/*"
                setImage={setImage}
                image={[image]}
              />
              {type?.id ? (
                <ButtonV1
                  color="blue"
                  type={"submit"}
                >
                  Add Category
                </ButtonV1>
              ) : (
                <ButtonV1
                  color="blue"
                  active={true}
                  type={"button"}
                >
                  Add Category
                </ButtonV1>
              )}
            </form>
          </EditModal>
        ))}
    </AdminLayout>
  );
}

export default CategoryPage;
