import { ChevronRightRounded, Group, Sort } from "@mui/icons-material";
import { ReactElement, useState } from "react";
import { useLocation } from "react-router-dom";
import { FilterItem } from "../atoms/filterItems";
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { FiltersType } from "../../types/types";

interface props {
  child: string;
  data: FiltersType[];
  handleChange?: Function;
  reduxData: {
    _id: string;
    title: string;
  }[];
}

export function FilterTab({ child, data, handleChange, reduxData }: props) {
  const [active, setActive] = useState(false);

  return (
    <div className="flex flex-col pb-2">
      <div
        className="flex justify-between items-center"
        onClick={() => setActive(!active)}
      >
        <p className="font-semibold text-md flex gap-2 items-center">
          <Sort style={{ fontSize: "15px" }} />
          {child}
        </p>
        <div className={`iconEffect ${active ? "iconRotate" : ""}`}>
          <ChevronRightRounded />
        </div>
      </div>
      <ul
        className={`w-full pt-2 overflow-hidden ${
          active ? "heightTransition" : "heightTransitionV2"
        } active `}
      >
        {data?.map((item) => (
          <FilterItem
            key={item._id}
            id={item._id}
            reduxData={reduxData}
            handleChange={handleChange}
          >
            {item.title}
          </FilterItem>
        ))}
      </ul>
    </div>
  );
}

interface propsAdmin {
  href: string;
  children: string;
  icon: ReactElement;
}

export function FilterTabV2({ href, children, icon }: propsAdmin) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  return (
    <div
      className="flex justify-between w-full items-center my-2 cursor-pointer"
      onClick={() => navigate(href)}
    >
      <p
        className={`${
          pathname === href
            ? "text-white bg-black px-2 py-2 w-[90%] rounded-full"
            : "text-gray-500 hover:text-white hover:bg-black px-2 py-2 w-[90%] rounded-full"
        }  flex gap-2 ml-1 items-center tracking-wide capitalize overflow-hidden whitespace-nowrap text-ellipsis`}
      >
        <>{icon}</>
        {children}
      </p>
    </div>
  );
}
