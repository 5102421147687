import apiSlice from "../features/apiSlice";
import { removeEmptyKeys } from "../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProduct: builder.query({
      query: (queries) => {
        return {
          url: '/product/admin',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['Product']
    }),
    createProduct: builder.mutation({
      query: (credentials) => ({
        url: "/product",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Product']
    }),
    updateProduct: builder.mutation({
      query: (credentials) => ({
        url: `/product/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['Product']
    }),
    bulkUpdateProduct: builder.mutation({
      query: (credentials) => ({
        url: `/product/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Product']
    }),
    deleteProduct: builder.mutation({
      query: (credentials) => ({
        url: `/product/${credentials.id}`,
        method: "DELETE"
      }),
      invalidatesTags:['Product']
    }),
    hardDeleteProduct: builder.mutation({
      query: (credentials) => ({
        url: `/product/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Product']
    }),
  }),
});

export const {
  useGetProductQuery,
  useLazyGetProductQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useBulkUpdateProductMutation,
  useDeleteProductMutation,
  useHardDeleteProductMutation
} = authApiSlice;
