import { AdminHeader } from "../molecules/header";
import { SidebarAdmin } from "../molecules/sidebar";

export default function AdminLayout({
  children,
}: {
  children: React.ReactNode;
}) {

  return (
    <div className="relative">
      <AdminHeader />
      <div className="flex min-h-[90vh]">
        <SidebarAdmin />
        <div className="w-[80vw] max-[800px]:w-[70vw] px-[3vw] py-[5vh] h-max min-h-[90vh] border-4 overflow-hidden">
          {children}
        </div>
      </div>
    </div>
  );
}
