import apiSlice from "../features/apiSlice";
import { removeEmptyKeys } from "../utils/helper";

const AnalyticsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAnalytics: builder.query({
      query: (queries) => {
        return {
          url: '/analytics',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['AnalyticsTag'],
    }),
    getMonthlyAnalytics: builder.query({
      query: (queries) => {
        return {
          url: '/analytics/monthly',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['AnalyticsTag'],
    }),
  }),
});


export const { useGetAnalyticsQuery, useGetMonthlyAnalyticsQuery } = AnalyticsApi;
