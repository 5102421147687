import React, { useState } from "react";
import AdminLayout from "../../components/organisms/adminLayout";
import DataGridBox from "../../components/organisms/dataGrid";
import { GridColDef } from "@mui/x-data-grid";
import { useGetRevenueListQuery } from "../../api/revenue";
import { date } from "yup";
import dayjs from "dayjs";

interface Props {}

function Revenue(props: Props) {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 1,
  });

  const { data: rawRevenueList } = useGetRevenueListQuery(paginationModel);

  console.log("Revenue data", rawRevenueList);

  const revenueList = rawRevenueList?.data?.data?.map(
    (item: any, index: number) => ({
      id: item._id.slice(-4),
      date: dayjs(item.createdAt).format('YYYY-MM-DD'),
      cost_of_product: item.totalOrderAmount,
      shipping_charge: item.totalShippingCharges,
      net_profit: item.profit,
    })
  );

  console.log("Revenue data", rawRevenueList)

  const columns: GridColDef<(typeof revenueList)[number]>[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "date",
      headerName: "Date",
      width: 150,
    },
    {
      field: "cost_of_product",
      headerName: "Cost of Product",
      width: 150,
    },
    {
      field: "shipping_charge",
      headerName: "Shipping Charge",
      width: 150,
    },
    {
      field: "net_profit",
      headerName: "Net Profit",
      width: 150,
    },
  ];


 

  return (
    <AdminLayout>
      <h1 className="mb-4 text-[2rem] font-semibold">Revenue</h1>
      <DataGridBox
        checkboxSelection={false}
        setPaginationModel={setPaginationModel}
        paginationModel={paginationModel}
        columns={columns}
        initialRows={revenueList || []}
        qty={revenueList?.length||0}
      />
    </AdminLayout>
  );
}

export default Revenue;
