import { Box, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import { InputFieldV1 } from "../atoms/inputField";
import { ButtonV1 } from "../atoms/button";
import { useUpdateCustomerMutation } from "../../api/users/users";
import snackbar from "../../api/snackbar/useSnackbar";
import { useFormik } from "formik";

interface Item {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isBlocked: boolean;
}
interface props {
  open: boolean;
  handleClose: Function;
  item: Item;
}

export function UserModel({ open, handleClose, item }: props) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "max-content",
    minWidth: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 4,
    p: 4,
  };
  const [update, { isLoading: updateIsLoading, isSuccess: updateSuccess }] =
    useUpdateCustomerMutation({});

  const Formik = useFormik({
    initialValues: {
      firstName: item?.firstName,
      lastName: item?.lastName,
      email: item?.email,
      phone: item?.phone,
      isBlocked: item?.isBlocked,
    },
    enableReinitialize: false,
    onSubmit: (values) => {
      update({ id: item?._id, body: values })
        .unwrap()
        .then((res) => {
          console.log(res);
          snackbar.success(res.message);
          handleClose();
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          className="absolute right-4 top-4 cursor-pointer h-max"
          onClick={() => handleClose()}
        >
          <Close className="" />
        </div>
        <form className="flex flex-col" onSubmit={Formik.handleSubmit}>
          <h1 className="text-lg font-medium mb-4 w-full">Edit Customer Details</h1>
          <InputFieldV1
            name="firstName"
            lable="First Name"
            type="text"
            value={Formik.values.firstName}
            errors={Formik.errors.firstName}
            handleChange={Formik.handleChange}
            handleBlur={Formik.handleBlur}
          />
          <InputFieldV1
            name="lastName"
            lable="Lasr Name"
            type="text"
            value={Formik.values.lastName}
            errors={Formik.errors.lastName}
            handleChange={Formik.handleChange}
            handleBlur={Formik.handleBlur}
          />
          <InputFieldV1
            name="email"
            lable="Email"
            type="email"
            value={Formik.values.email}
            errors={Formik.errors.email}
            handleChange={Formik.handleChange}
            handleBlur={Formik.handleBlur}
          />
          <InputFieldV1
            name="phone"
            lable="Phone"
            type="text"
            value={Formik.values.phone}
            errors={Formik.errors.phone}
            handleChange={Formik.handleChange}
            handleBlur={Formik.handleBlur}
          />
          <ButtonV1 color="blue" type="submit">
            Update
          </ButtonV1>
        </form>
      </Box>
    </Modal>
  );
}
