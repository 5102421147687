import React, { ReactElement } from "react";

interface props {
  products: boolean;
  icon: ReactElement;
  iconbg: string;
  title: string;
  change: number;
  value: number;

}

export default function StatusCard({ products,icon, iconbg, title, value, change }: props) {
  console.log(typeof(change), change);
  
  return (
    <div className="rounded-lg border border-[#e0e2e7] p-5 inline-flex flex-col w-[30%] min-w-[260px] shadow-md">
      <div className={`w-fit p-2 ${iconbg} rounded-full`}>
        {icon}
      </div>
      <h1 className=" text-base font-medium text-[#667085]  pt-4">{title}</h1>
      <div className=" flex gap-4 pt-2 items-center">
        <h2 className=" text-2xl font-medium text-[#333843]">{!products && "₹"}{ value> 10000 ? (((value/1000).toFixed(2)).toLocaleString()+"k") : value}{products && " units"}</h2>
        {
          change !== 0 ?
          <h1
            className={`  rounded-full px-[6px] py-[2px] text-[12px] ${
              change > 0
                ? "bg-[#E7F4EE] text-[#0D894F]"
                : change < 0
                ? "bg-[#FEEDEC] text-[#F04438]"
                : "bg-[#F0F1F3] text-[#667085]"
            }`}
          >
            {(change === -1 || isNaN(change)  ) ? "No Data" : `${change}%`}
          </h1>
          : ''
        }
      </div>
    </div>
  );
}
