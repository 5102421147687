import { Box, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import "./productModal.css";
import { CategoryType } from "../../types/types";
import {
  FileInputField,
  InputFieldV1,
  MultiFileInputField
} from "../atoms/inputField";
import { useFormik } from "formik";
import snackbar from "../../api/snackbar/useSnackbar";
import {
  useCreateTypesMutation,
  useUpdateTypesMutation,
} from "../../api/filters/types";
import {
  TypesSchema,
  UpdateTypesSchema,
} from "../../validationSchema/typesSchema";
import { ButtonV1 } from "../atoms/button";

interface props {
  item?: CategoryType;
  open: boolean;
  handleClose: Function;
}

export function AddTypesModal({ open, handleClose }: props) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 4,
  };

  const [create, { isLoading: createIsLoading, isSuccess: createSuccess }] =
    useCreateTypesMutation({});

  const Formik = useFormik({
    initialValues: {
      name: " ",
      imgUrl: " ",
      isFeatured: true,
      metaData: {
        title: " ",
        keywords: " ",
        description: " ",
      },
    },
    enableReinitialize: true,
    validationSchema: TypesSchema,
    onSubmit: (values) => {
      create({ ...values })
        .unwrap()
        .then((res: { message: string }) => {
          console.log(res);
          snackbar.success(res.message);
          handleClose();
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  console.log(Formik.errors);
  console.log(Formik.values);

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} height={"90vh"} overflow={"scroll"}>
        <div
          className="absolute right-4 top-4 cursor-pointer"
          onClick={() => handleClose()}
        >
          <Close className="" />
        </div>
        <h1 className="font-semibold text-xl py-6 pb-2 px-8 mb-4 border-b">
          Create Types
        </h1>
        <form
          className="w-full flex flex-wrap p-8 pb-0 pt-0 justify-between"
          noValidate
          onSubmit={Formik.handleSubmit}
        >
          <InputFieldV1
            name="name"
            lable="Name"
            type="text"
            placeholder="Name"
            value={Formik.values.name}
            errors={Formik.errors.name}
            handleChange={Formik.handleChange}
            handleBlur={Formik.handleBlur}
          />
          <MultiFileInputField
            name="img"
            lable="Thumbs"
            type="file"
            accept="image/*"
            formik={Formik}
            image={[Formik.values.imgUrl]}
          />
          <h1 className="w-full font-bold text-lg mb-4 mt-2">SEO Attributes</h1>
          <InputFieldV1
            width="half"
            name="metaData.title"
            lable="Title"
            type="text"
            placeholder="Title"
            value={Formik.values.metaData && Formik.values.metaData.title}
            errors={Formik.errors.metaData && Formik.errors.metaData.title}
            handleChange={Formik.handleChange}
            handleBlur={Formik.handleBlur}
          />
          <InputFieldV1
            width="half"
            name="metaData.keywords"
            lable="Keyword"
            type="text"
            placeholder="Keyword"
            value={
              Formik.values.metaData && Formik.values.metaData.keywords
            }
            errors={
              Formik.errors.metaData && Formik.errors.metaData.keywords
            }
            handleChange={Formik.handleChange}
            handleBlur={Formik.handleBlur}
          />
          <InputFieldV1
            width="half"
            name="metaData.description"
            lable="Description"
            type="text"
            placeholder="Description"
            value={
              Formik.values.metaData && Formik.values.metaData.description
            }
            errors={
              Formik.errors.metaData && Formik.errors.metaData.description
            }
            handleChange={Formik.handleChange}
            handleBlur={Formik.handleBlur}
          />
          <div className="w-full flex justify-end p-8 pt-0">
            <div className="w-max">
              <ButtonV1 color="blue" type="submit">
                Save
              </ButtonV1>
            </div>
          </div>
        </form>
      </Box>
    </Modal>
  );
}

export function EditTypesModal({ item, open, handleClose }: props) {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 4,
  };

  const [update, { isLoading: updateIsLoading, isSuccess: updateSuccess }] =
    useUpdateTypesMutation({});

  const Formik = useFormik({
    initialValues: {
      name: item?.name || "",
      imgUrl: item?.imgUrl || "",
      isFeatured: item?.isFeatured,
      metaData: {
        title: item?.metaData?.title || "",
        keywords: item?.metaData?.keywords || "",
        description: item?.metaData?.description || "",
      },
    },
    enableReinitialize: true,
    validationSchema: UpdateTypesSchema,
    onSubmit: (values) => {
      update({ id: item?._id, body: values })
        .unwrap()
        .then((res: { message: string }) => {
          console.log(res);
          snackbar.success(res.message);
          handleClose();
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  console.log(Formik.errors);
  console.log(Formik.values);

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} height={"90vh"} overflow={"scroll"}>
        <div
          className="absolute right-4 top-4 cursor-pointer"
          onClick={() => handleClose()}
        >
          <Close className="" />
        </div>
        <h1 className="font-semibold text-xl py-6 pb-2 px-8 mb-4 border-b">
          Update Types
        </h1>
        <form
          className="w-full flex flex-wrap p-8 pb-0 pt-0 justify-between"
          noValidate
          onSubmit={Formik.handleSubmit}
        >
          <InputFieldV1
            name="name"
            lable="Name"
            type="text"
            placeholder="Name"
            value={Formik.values.name}
            errors={Formik.errors.name}
            handleChange={Formik.handleChange}
            handleBlur={Formik.handleBlur}
          />
          <MultiFileInputField
            name="img"
            lable="Thumbs"
            type="file"
            accept="image/*"
            formik={Formik}
            image={[Formik.values.imgUrl]}
          />
          <h1 className="w-full font-bold text-lg mb-4 mt-2">SEO Attributes</h1>
          <InputFieldV1
            width="half"
            name="metaData.title"
            lable="Title"
            type="text"
            placeholder="Title"
            value={Formik.values.metaData && Formik.values.metaData.title}
            errors={Formik.errors.metaData && Formik.errors.metaData.title}
            handleChange={Formik.handleChange}
            handleBlur={Formik.handleBlur}
          />
          <InputFieldV1
            width="half"
            name="metaData.keywords"
            lable="Keyword"
            type="text"
            placeholder="Keyword"
            value={
              Formik.values.metaData && Formik.values.metaData.keywords
            }
            errors={
              Formik.errors.metaData && Formik.errors.metaData.keywords
            }
            handleChange={Formik.handleChange}
            handleBlur={Formik.handleBlur}
          />
          <InputFieldV1
            width="half"
            name="metaData.description"
            lable="Description"
            type="text"
            placeholder="Description"
            value={
              Formik.values.metaData && Formik.values.metaData.description
            }
            errors={
              Formik.errors.metaData && Formik.errors.metaData.description
            }
            handleChange={Formik.handleChange}
            handleBlur={Formik.handleBlur}
          />
          <div className="w-full flex justify-end p-8 pt-0">
            <div className="w-max">
              <ButtonV1 color="blue" type="submit">
                Save
              </ButtonV1>
            </div>
          </div>
        </form>
      </Box>
    </Modal>
  );
}
